import { sortAlphabetically } from "./sorting";

export const mapHouseViewAndHouseType = async(houseViews, houseTypes ) => {
  if (houseViews[0]?.developmentImage === null && houseViews[0]?.floors.length === 0 && houseViews[0]?.houseTypeId === null) return [];
  const combined = await Promise.all(houseViews.map((house)=>{
    const findHouseType = houseTypes.filter((ht) => ht.id === house.houseTypeId);
    return {...findHouseType[0], ...house, houseViewId: house.id, options: house.options, name: findHouseType[0]?.name ? findHouseType[0]?.name : 'Error House Type'};
  }))
  return sortAlphabetically(combined, 'name');
}

export const combineHouseTypeAndHouseView = async(houseTypes, houseViews) => {
  const combined = await Promise.all(houseTypes.map((house)=>{
    const findHouseType = houseViews.filter((hv) => hv.houseTypeId === house.id);
    return {...findHouseType[0], ...house, houseViewId: findHouseType[0]?.id, FloorOptions: findHouseType[0]?.options};
  }))
  return sortAlphabetically(combined, 'name');
}