import { ApiError, beablooApi } from '../../utils';
import { setMortgageCalculatorMenu } from './optionalMenuItems';

const setMortgageCalculator = () => {
  return async (dispatch, getState) => {
    const { general } = getState();

    const response = await beablooApi({
      method: 'GET',
      route: `/developments/${general.currentDevelopment.id}/pages/mortgageCalculator`,
    })
      .then((r) => {
        if (r.success) {
          return r.data;
        } else {
          dispatch(setMortgageCalculatorMenu(false));
        }
      })
      .catch((e) => {
        console.error(e);
      });

    dispatch({
      type: 'SET_MORTGAGE_CALCULATOR',
      data: response,
    });
  };
};

export { setMortgageCalculator };
