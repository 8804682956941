const sortAlphabetically = (array, attribute) => {
  try {
    return array.sort((a, b) => {
      return a[attribute].localeCompare(b[attribute]);
    });
  } catch (error) {
    console.error(error);
    return array;
  }
};

export { sortAlphabetically };