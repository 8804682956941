import schema from '../schemas/_optionalMenu';

const optionalMenuReducer = (state = schema, action) => {
  switch (action.type) {
    case 'SET_HOUSE_VIEW_MENU':
      return {
        ...state,
        houseViewMenu: action.data
      };
    case 'SET_MORTGAGE_CALCULATOR_MENU':
      return {
        ...state,
        mortgageCalculatorMenu: action.data
      };
    case 'SET_STREET_VIEW_MENU':
      return {
        ...state,
        streetViewMenu: action.data
      };
    case 'RESET_MENU_ITEMS':
      const keys = Object.keys(state);
      keys.forEach(key => {
        state[key] = true;
      });
      return {
        ...state
      };
    default:
      return {
        ...state
      };
  }
};

export { optionalMenuReducer };
