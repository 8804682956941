import { Box } from "@mui/material";
import { Label } from "../../../components/FormV2/components";
import { useDraggable } from "@dnd-kit/core";

export const StatusBox = ({status}) => {
  const draggableId = status.label;
  const { attributes, listeners, transform, setNodeRef } = useDraggable({
    id: draggableId
  });
  return (
    <Box id={draggableId}
      ref={setNodeRef}
      data={status}
      {...attributes}
      {...listeners}
      padding={'0.8rem'}
      margin={'0.5rem'}
      backgroundColor={'#f0f0f0'}
      borderRadius={'0.4rem'} display={'flex'}
      alignItems={'center'}
      sx={{ transform: `translate3d(${transform?.x}px, ${transform?.y}px, 0)`, cursor: 'grab' }}
      >
      <Box borderRadius={'50%'} width={'0.5rem'} height={'0.5rem'} backgroundColor={status.color ?? '#000'} marginRight={'1rem'}/>
      <Label>{status.label}</Label>
    </Box>
  );
};