import React, { useEffect, useState } from 'react'
import { Heading, Page } from '../../components'
import { Box } from '@mui/material'
import { blobServices } from '../../lib'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { EditIcon } from '../../components/Icons'
import './styles.scss'
import { PencilSquareIcon } from '@heroicons/react/24/solid'
const StreetViewConfiguration = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const currentDevelopment = useSelector(
    (state) => state.general.currentDevelopment,
  );
  const { streetPins } = useSelector(
    (state) => state.streetPins
  );

  return (
    <Page>
      <Box sx={{ maxWidth: '100vw', width: '100%' }}>
        <div className='heading' style={{ marginTop: '12px' }} >
          <Heading label='Street View Internal Configuration' />
        </div>
      </Box>

      {loading ?
      <div style={{
        fontSize: '14px',
      }}>Loading...</div> :
      <>
      {streetPins &&
      <table cellSpacing={0}>
        <thead>
          <tr>
            <th width='auto' 
            // onClick={(e) => toggleSort('number')}
            >
              <div className='table-sort-container'>
                <p>Sr.No</p>
              
              </div>
            </th>
            <th width='auto'>Pin Number</th>
          </tr>
        </thead>
        <tbody>
        {streetPins.map((pin,index) => {
          return (
            <tr key={Math.random().toString(16)} >
              <td>{index+1}</td>
              <td>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div>
                    Pin Number {pin.id}
                  </div>
                  <PencilSquareIcon
                    style={{ cursor: 'pointer' }}
                    width={'20px'} height={'20px'} 
                    onClick={() => {navigate(`/developments/${currentDevelopment.id}/street-view/configuration/${pin.id}`)}} />
                </div>
              </td>
            </tr>
          );
        })}
        </tbody>
      </table>
      }
      </>
      }
      {!streetPins && 'No Street Pins Found'}
    </Page>
  )
}

export default StreetViewConfiguration