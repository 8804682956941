import { blobServices } from "../../lib"
import { ApiError } from "../../utils";
import { setStreetViewMenu } from "./optionalMenuItems";

const getStreetPins = () => {
  return async (dispatch, getState) => {
    const fileName = 'ModelConfigrations.json';
    const jsonFile = await blobServices.download(fileName)
    .then((res) => res.data)
    .catch((e) => {
      console.log(e);
      dispatch(setStreetViewMenu(false));
    });
    if (jsonFile) {
      dispatch({
        type: 'SET_STREET_PINS',
        data: jsonFile?.streetViewPins,
      });
    }
  }
}

const getEnvironment = () => {
  return async (dispatch, getState) => {
    const fileName = 'EnviornmentConfigrations.json';
    const jsonFile = await blobServices.download(fileName)
    .then((res) => res.data)
    .catch((e) => {
      console.log(e);
      dispatch(setStreetViewMenu(false));
    });
    if (jsonFile) {
      dispatch({
        type: 'SET_ENVIRONMENT',
        data: jsonFile,
      });
    }
  }
}

const updateEnvironment = (data) => {
  return async (dispatch, getState) => {
    dispatch({
    type: 'SET_ENVIRONMENT',
    data: data,
    });
  }
}

export {
  getStreetPins,
  getEnvironment,
  updateEnvironment,
}