import { Heading, Page } from '../../components';
import { Box, Typography } from '@mui/material';
import { Button, TextInput, Select, Price, Label } from '../../components/FormV2/components';
import { useYupResolver } from '../../hooks';
import { useForm } from 'react-hook-form';
import { plotSchema } from './plotSchema';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import plotService from '../../lib/signtouch/plotsService';
import { setPlots } from '../../store/actions/plots';
import addPending from '../../utils/addPending';
import removePending from '../../utils/removePending';
import { addError, addNotification } from '../../utils';
import { statusesAsOptions } from './constants';
import { LabeledToggle } from '../../components/Toggle/LabeledToggle';
 
export const CreatePlotPage = () => {
  const properties = useSelector((state) => state.properties);
  const [isAffordable, setIsAffordable] = useState(false);
  const { map } = properties.branding;
  let statusTypesAsOptions = [];
  Object.keys(map)
    .filter(key => !key.endsWith("label") && !key.endsWith("category"))
    .reduce((obj, key) => {
      obj = { label: map[key + "label"], value: key };
      statusTypesAsOptions.push(obj);
    }, {});

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const resolver = useYupResolver(plotSchema);
  const { control, handleSubmit } = useForm({
    resolver,
  });

  const [saving, setSaving] = useState(false);

  const { developmentId } = useParams();
  const houseTypes = useSelector(state => state.houseTypes);
  const houseTypesAsOptions = houseTypes.map(houseType => ({
    label: houseType.name,
    value: houseType.id
  }));

  const onSubmit = async data => {
    console.log({...data, isAffordable});
    setSaving(true);
    const pendingToast = addPending('Creating plot');

    try {
      await plotService.createPlot({...data, isAffordable: isAffordable});
      await dispatch(setPlots());
      removePending(pendingToast);
      addNotification('Successfully created plot');
      setSaving(false);
      navigate(`/developments/${developmentId}/plots`)
    } catch (error) {
      removePending(pendingToast);
      addError('Unable to create plot');
      setSaving(false);
    }
  };
  return (
    <Page>
      <Heading label={`Create Plot`} />

      <Box
        sx={{
          maxWidth: '600px', p: 4, backgroundColor: 'white', borderRadius: 2
        }}
      >
        <Box as='div' display='flex' justifyContent='space-between' alignItems='center'>
          <Heading label={`Create Plot`} headingStyles={{ margin: 0 }}/>
          <Box as='div' display='flex' alignItems='center'>
            <Label styles={{ marginRight: '10px' }}>Affordable</Label>
            <LabeledToggle
              name='isAffordable'
              initialValue={isAffordable}
              onChange={(value) => setIsAffordable(value)}
            />
          </Box>
        </Box>
        <Typography sx={{ mt: 1, mb: 2 }}>
          Create a plot, you can plot it on the map later.
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Box sx={{
              width: '50%', display: 'flex', flexDirection: 'column', gap: 1
            }}>
              <TextInput
                full
                name='number'
                label='PLOT NUMBER'
                control={control}
              />
              <Price
                full
                name='price'
                label='PRICE'
                control={control}
              />
              <Select
                full
                name='status'
                label='STATUS'
                control={control}
                options={statusTypesAsOptions}
              />
              <Select
                full
                name='houseType'
                label='HOUSE TYPE'
                control={control}
                options={houseTypesAsOptions}
              />
            </Box>
            <Button type='submit' disabled={saving}>{saving ? 'Saving...' : 'Save'}</Button>
          </Box>
        </form>
      </Box>
    </Page>
  );
};
