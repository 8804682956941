import React, { useEffect, useState } from 'react'
import { Heading, Page, SubHeading } from '../../components'
import { useSelector } from 'react-redux';
import { Box, Card, CardContent, CardMedia, Grid,Tooltip} from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import houseTypeImage from '../../assets/img/temp/signtouch-logo.svg'
import { BuildingOfficeIcon } from '@heroicons/react/24/solid';
import { combineHouseTypeAndHouseView } from '../../utils/combineHouseTypeAndHouseView';
import { Chair, Layers, Room, Streetview } from '@mui/icons-material';
import { addError } from '../../utils';
import './styles.scss';

export const HouseViewConfigration = () => {
  const houseTypes = useSelector(state => state.houseTypes);
  const houseViews = useSelector(state => state.houseView);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { developmentId } = useParams();
  const [houseViewCards, setHouseViewCards] = useState([]);


  const handleMapNavigation = (houseId, floorCount) => {
    if (floorCount === 0) {
      addError('Selected House Has No Floors');
    } else {
      navigate(`/developments/${developmentId}/house-view/map/${houseId}`)
    }
  }

  const handleStreetViewNavigation = (houseId, houseData) => {
    if (houseData.floors.length === 0) {
      addError('Selected House Has No Floors');
    } else if (houseData.floors.length > 0 && houseData.FloorOptions.length === 0) {
      addError('Selected House Hase No Room / Area');
    } else {
      navigate(`/developments/${developmentId}/street-view/configuration/1?houseViewId=${houseId}`)
    }
  }

  useEffect(()=> {
    combineHouseTypeAndHouseView(houseTypes, houseViews).then((combined) => {
      setHouseViewCards(combined);
      setLoading(false);
    });
  },[houseTypes, houseViews])

  return (
    <Page>
      <Box sx={{ maxWidth: '100vw', width: '100%' }}>
        <div className='heading' style={{ marginTop: '12px' }} >
          <Heading label='House Internal Configuration' />
        </div>
        <SubHeading>
          Here you can configure the internal experience for each house type.
        </SubHeading>
        <Grid container spacing={2} sx={{ marginTop: '12px' }}>
          {loading
          ? 'loading...'
          :houseViewCards.map((house, index)=> (
            <Grid key={index} item xs={12} sm={6} md={4} xl={3}>
              <Card sx={{ display: 'flex', position: 'relative', height: '100%', minHeight: '8rem' }}>
                <Box sx={{display: 'flex' ,width:'30%', minWidth: '30%' }}>
                  <CardMedia
                    component={'img'}
                    image={ house.thumbnail ? house.thumbnail.url : houseTypeImage}
                    alt={'house card image'}
                    />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column'  }}>
                  <CardContent sx={{ flex: '1 0 auto'}}>
                  <div className='house-card-about'>
                    <h3>{house.name}</h3>
                    <div className='house-card-info'>
                      <BuildingOfficeIcon />
                      <div className='house-name-bedrooms'>
                        <p>floor count: {house.floors ? house.floors.length : 0}</p>
                      </div>
                    </div>
                  </div>
                  </CardContent>
                </Box>
                <div className='actions'>
                  <Link to={`/developments/${developmentId}/house-view/multi-floor/configuration/${house.id}`}>
                    <Tooltip title='Floors'>
                      <Layers/>
                    </Tooltip>
                  </Link>
                  {house.floors &&
                    <>
                      <Link to={`/developments/${developmentId}/house-view/floor-options/${house.houseViewId}`}>
                        <Tooltip title='Rooms / Areas'>
                          <Chair/>
                        </Tooltip>
                      </Link>
                      <Tooltip title='Map'>
                        <Room onClick={()=>handleMapNavigation(house.houseViewId, house.floors.length)}/>
                      </Tooltip>
                      <Tooltip title='House View'>
                        <Streetview onClick={() => handleStreetViewNavigation(house.houseViewId, house)}/>
                      </Tooltip>
                    </>
                  }
                </div>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
      {houseViewCards.length === 0 && <p>No House Views available</p>}
    </Page>
  )
}
