import { Billboard, Environment, Html, OrbitControls, Sphere, useGLTF, useProgress } from "@react-three/drei"
import { useFrame, useThree } from "@react-three/fiber"
import React, { useEffect, useRef, useState } from "react"
import * as THREE from 'three';
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { sortCubeMapUrls } from "../../utils/sortCubemapUrl";
import FiberTooltip from "../FiberToolTip";
import { ArrowBack } from "@mui/icons-material";
import { CircularSliderWithChildren } from 'react-circular-slider-svg';
import { degToRad } from "three/src/math/MathUtils.js";
import entranceGlb from '../../assets/icons/entranceIcon.glb';
import insideHouseGlb from '../../assets/icons/insideHouse.glb';
import './styles.scss'
import blobServices from "../../lib/signtouch/blobServices";
import { addError } from "../../utils";

const PanoramaViewer = ({ parentPanoramas, inOpreation, setParentPanoramas, mode, setMode, savePin, saveAngle, deletePin, setClickedData, editor, setEditor, beingViewed, setBeingViewed, rerender, houseViewId}) => {
  // get params
  const { pinId, developmentId } = useParams();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery({ query: '(max-width: 1200px)' });
  const { currentDevelopment } = useSelector((state) => state.general);
  const [isLoading, setIsLoading] = useState(true);
  const mediaurl = currentDevelopment?.mediaUrl; // actual url
  const loadMedia = (type, files) => {
    const urls = files.map((face) => 
    {
      switch (type) {
        case 'panorama': 
          return `/${face}`;
        case 'pin':
          return `/pin_${pinId}_${face}.jpg`;
        default:
          return `/pin_${pinId}_${face}.jpg`
      }
    }
    );
    return sortCubeMapUrls(urls);
  }
  const [panoramas, setPanoramas] = useState([]);
  const allHouseViews = useSelector((state) => state.houseView);
  const [houseView, setHouseView] = useState( houseViewId ? allHouseViews.filter((view) => view.id === parseInt(houseViewId))[0] : null);
  const  [ getHouseView ] = useSelector((state) => state.houseView.filter((view) => view.id === houseView?.id));
  const { camera, scene, size, gl } = useThree();
  const glDom = gl.domElement;
  const raycaster = new THREE.Raycaster();
  const { active } = useProgress();
  const { environmentConfig } = useSelector((state) => state.environmentConfig);
  const [azimuthalAngle, setAzimuthalAngle] = useState(environmentConfig[`pin_${pinId}_settings`]?.azimuthalAngle ?? 0);
  const entranceIcon = useGLTF(entranceGlb);
  const insideHouseIcon = useGLTF(insideHouseGlb);
  const sphereRef = useRef();
  const oribitalControls = useRef();
  const menuWidth = isSmallScreen ? 0 : (document.getElementsByClassName('side-bar')[0]?.offsetWidth ?? 57);
  const topBarHeight = document.getElementsByClassName('development-banner')[0].offsetHeight ?? 262;
  
  const handleClick = (event) => {
    if (inOpreation) return;
    if (editor) {
      if (!sphereRef.current) return;
      const mouse = new THREE.Vector2();
      mouse.x = (((event.clientX) / (size.width)) * 2 - 1) - (menuWidth * 2 / size.width);
      mouse.y = -((event.clientY) / (size.height)) * 2 + 1 + (topBarHeight * 2 / size.height);  
      raycaster.setFromCamera(mouse, camera);
      const intersects = raycaster.intersectObjects(scene.children, true);
      if (intersects.length > 0) {
        const point = intersects[0].point;
        savePin({point, azimuthalAngle});
      }
    }
  }

  const getSasUrls = async (urls) => {
    if (urls.length === 0) return [];
    setIsLoading(true);
    try {
      const sasUrls = await blobServices.genrateUrls(urls);
      return sortCubeMapUrls(sasUrls);
    } catch (error) {
      console.error("Error fetching SAS URLs:", error);
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  const handleArrowClick = async (files, data) => {
    setClickedData(data);
    if (mode === 'default' || mode === 'house-view') {
      if (files.length === 0) return;
      const urls = loadMedia('panorama', files);
      await getSasUrls(urls).then((sasUrls) => {
        setPanoramas(sasUrls); // Update state with new panorama URLs
      });
    } else if (mode === 'delete') {
      if (inOpreation) return;
      deletePin({...data});
      // setMode(beingViewed ? 'house-view' : 'default');
    }
  };

  const handleAngleChange = (angle) => {
    if (oribitalControls.current) {
      oribitalControls.current.setPolarAngle(degToRad(90));
      oribitalControls.current.setAzimuthalAngle(degToRad(angle));
      setAzimuthalAngle(Math.round(angle));
    }
  };

  const backButton = async () => {
    if (mode !== 'house-view') {
      navigate(`/developments/${developmentId}/street-view/configuration`)
    } else if (houseViewId) {
      navigate(`/developments/${developmentId}/house-view/configuration`)
    } else {
      setBeingViewed(null);
      setMode('default');
      setAzimuthalAngle(environmentConfig[`pin_${pinId}_settings`]?.azimuthalAngle ?? 0);
      const files = loadMedia('pin', ['px', 'nx', 'py', 'ny', 'pz', 'nz']);
      await getSasUrls(files).then(sasUrls => {
        setPanoramas(sasUrls);
      });
    }
  };
  // this is for resizing the canvas
  useEffect(() => {
    const handleResize = () => {
      const canvas = gl.domElement;
      const width = canvas.clientWidth;
      const height = canvas.clientHeight;
  
      if (canvas.width !== width || canvas.height !== height) {
        gl.setSize(width, height, false);
        camera.aspect = width / height;
        camera.updateProjectionMatrix();
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize(); // Call once to set initial size
    return () => window.removeEventListener('resize', handleResize);
  }, [camera, gl]);

  // this is everytime when we update panorama from parent or when we click on a pin
  // useEffect(() => {
  //   const fetchPanoramas = async () => {
  //     if (!houseViewId) {
  //         setBeingViewed(null);
  //         setMode('default');
  //         const files = loadMedia('pin', ['px', 'nx', 'py', 'ny', 'pz', 'nz']);
  //         const sasUrls = await getSasUrls(files);
  //         setPanoramas(sasUrls);
  //       } else {
  //         const files = loadMedia('panorama', parentPanoramas.panoramas.map((panorama) => panorama.url.replace('/', '')));
  //         const sasUrls = await getSasUrls(files);
  //         setPanoramas(sasUrls);
  //         setAzimuthalAngle(parentPanoramas?.azimuthalAngle ?? 0);
  //       }
  //     }
  //   fetchPanoramas();
  // }, [parentPanoramas, pinId]);
  // this is called on every new render (in case of inside house) to get updated house view
  useEffect(() => {
    if (getHouseView) {
      setHouseView(getHouseView);
    }
  }, [rerender]);
  // this is for setting angle of camera on first load
  useEffect(() => {
    if (oribitalControls.current && !isLoading) {
      oribitalControls.current.setAzimuthalAngle(degToRad(azimuthalAngle));
    }
  }, [oribitalControls, azimuthalAngle, isLoading]);
  // this is for cattring the house view id from url i.e when we choose a house view from house view configuration
  useEffect(() => {
    const fetchPanoramas = async () => {
      try {
        let urls;
        if (!beingViewed && !houseViewId) {
          setBeingViewed(null);
          setMode('default');
          urls = loadMedia('pin', ['px', 'nx', 'py', 'ny', 'pz', 'nz']);
        } else if (parentPanoramas) {
          const files = loadMedia('panorama', parentPanoramas.panoramas.map((panorama) => panorama.url.replace('/', '')));
          const sasUrls = await getSasUrls(files);
          setPanoramas(sasUrls);
          setAzimuthalAngle(parentPanoramas?.azimuthalAngle ?? 0);
        } else {
          const files = beingViewed.panoramas.map((panorama) => panorama.url.replace('/', ''));
          urls = loadMedia('panorama', files);
        }
         const sasUrls = await getSasUrls(urls);
         setPanoramas(sasUrls);
      } catch (error) {
        console.error('Error fetching panoramas:', error);
      }
    };

    fetchPanoramas();
  }, [parentPanoramas, pinId]);
  return (
    <group onClick={handleClick}>
      {isLoading && 
        <Html fullscreen>
          <div style={{ display:'flex', justifyContent:'center', alignItems: 'center', width: '100%', height:'100vh' }}>
            loading...
          </div>
        </Html>}
      {!isLoading &&
      <>
        <Environment blur={active ? 0.5 : 0} files={ panoramas } background />
        <Sphere ref={sphereRef} args={[500, 60,40]}  scale={[-1, 1, 1]}>
          <meshBasicMaterial visible={false} side={THREE.BackSide}/>
        </Sphere>
        { mode === 'house-view' || (mode === 'delete' && beingViewed)
        ? 
          houseView.housePaths.length > 0 && houseView.housePaths.map((path, index) => (
            <React.Fragment key={index}>
              {beingViewed?.id === path?.optionId &&
                <group key={index} position={new THREE.Vector3(path.position.x, path.position.y, path.position.z)} scale={26} rotation={[0, 0, 0]}>
                  <InternalHouseArrow nodes={insideHouseIcon.nodes} materials={insideHouseIcon.materials} data={path} load={handleArrowClick} mode={mode} setMode={setMode} setHouseView={setHouseView} beingViewed={beingViewed} setBeingViewed={setBeingViewed} setAzimuthalAngle={setAzimuthalAngle}/>
                </group>
              }
            </React.Fragment>
          )) 
        :
          environmentConfig[`house_entry_links_${pinId}`] && environmentConfig[`house_entry_links_${pinId}`].map((link, index) => (
            <group key={index} position={new THREE.Vector3(link.position.x, link.position.y, link.position.z)} scale={4.5}>
              <Arrow nodes={entranceIcon.nodes} materials={entranceIcon.materials} data={link} load={handleArrowClick} mode={mode} setMode={setMode} setHouseView={setHouseView} beingViewed={beingViewed} setBeingViewed={setBeingViewed} setAzimuthalAngle={setAzimuthalAngle} />
            </group>) 
          )
        }
        <Html fullscreen style={{ width: '100px', height: glDom.height}}>
          <div style={{ height:'100%', display: active ? 'none' : 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <div className="buttonWithIcon" style={{ margin: '10px' }}>
              <ArrowBack style={{ cursor: 'pointer', fill: '#fff', fontSize: '2rem' }} onClick={()=> backButton()} />
            </div>
            <div style={{ margin: '10px' }}>
              <CircularSliderWithChildren
                minValue={0}
                maxValue={360}
                size={150}
                handle1={{
                  value: azimuthalAngle,
                  onChange: (v) => {
                    handleAngleChange(v);
                  }
                }}
              >
                <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#fff', fontSize: '1.5rem' }}>
                  <div style={{ textAlign: 'center' }}>
                    {beingViewed && <>
                    {getHouseView.options.filter(hv => hv?.id === beingViewed?.id)[0]?.azimuthalAngle === azimuthalAngle
                      ? <label style={{ fontSize: '12px' }}>Default Angle</label>        
                      : <label onClick={()=> saveAngle({azimuthalAngle: azimuthalAngle})} style={{ fontSize: '12px', cursor: 'pointer', background: 'red', padding: '4px', borderRadius: '5px' }}>Set Angle</label> }
                    </>}
                    {!beingViewed && <>
                    {environmentConfig[`pin_${pinId}_settings`]?.azimuthalAngle === azimuthalAngle
                      ? <label style={{ fontSize: '12px' }}>Default Angle</label>        
                      : <label onClick={()=> saveAngle({azimuthalAngle: azimuthalAngle})} style={{ fontSize: '12px', cursor: 'pointer', background: 'red', padding: '4px', borderRadius: '5px' }}>Set Angle</label> }
                    </>}
                    <br/>
                    <label style={{ cursor: 'pointer' }} onClick={()=> handleAngleChange(azimuthalAngle)}>{Math.round(azimuthalAngle)}°</label>
                  </div>
                </div>
              </CircularSliderWithChildren>
            </div>
          </div>
        </Html>
        <OrbitControls ref={oribitalControls} zoomSpeed={0} enablePan={false} enableZoom={false} enableDamping={false} rotateSpeed={-0.35} />
      </>}
    </group>
  )
}

const Arrow = ({nodes, materials, data, load, mode, setMode, setHouseView, beingViewed, setBeingViewed, setAzimuthalAngle}) => {

  const arrowRef = useRef();
  const houseView = useSelector((state) => state.houseView.filter((view) => view.id === data.house_view_id)[0]);
  const houseType = useSelector((state) => state.houseTypes.filter((type) => type.id === houseView?.houseTypeId)[0]);
  const handleClick = () => {
    if (mode === 'default') {
      if (houseView) {
        const firstFloor = houseView?.floors.find((item) => item.order === 1);
        let firstAreaOnFloor = houseView.options.find((option) => option.floorId === firstFloor.id);
        if (!firstAreaOnFloor && houseView.options.length > 0) {
          firstAreaOnFloor = houseView.options[0];
        } else if (!firstAreaOnFloor && houseView.options.length === 0) {
          addError('selected house have no Room / Area');
          return;
        }
        setBeingViewed(firstAreaOnFloor);
        setAzimuthalAngle(firstAreaOnFloor?.azimuthalAngle ?? 0);
        if (firstAreaOnFloor.panoramas) {
          const files = firstAreaOnFloor.panoramas.map((panorama) => panorama.url.replace('/', ''));
          load(files, {data, houseView, houseType});
          setHouseView(houseView);
          setMode('house-view');
        }
      }
    } else if (mode === 'house-view') {
      if (houseView) {
        const [ getPanorama ] = houseView.options.filter((option) => option.id === data?.pinOptionId);
        setAzimuthalAngle(getPanorama?.azimuthalAngle ?? 0);
        if (getPanorama.panoramas) {
          const files = getPanorama.panoramas.map((panorama) => panorama.url.replace('/', ''));
          setBeingViewed(getPanorama);
          load(files, {data, houseView, houseType});
        }
      }
    } else if (mode === 'delete') {
      load([], {data, houseView, houseType});
    }
  }

  return (
    <>
    {nodes.MapPointer5_MapPointer5_0 && materials.MapPointer5 &&
      <FiberTooltip text={beingViewed ? houseView.options.filter((f)=> f.id === data.pinOptionId)[0]?.name : houseType?.name}>
        <Billboard follow={true} lockX={true} lockY={true} lockZ={true}>
          <group dispose={null} onClick={()=>handleClick()} rotation={[-Math.PI/ 2, 0, 0]} scale={6}>
            <ambientLight intensity={0.2} />
            <mesh ref={arrowRef} geometry={nodes.MapPointer5_MapPointer5_0.geometry} material={materials.MapPointer5} />
          </group>
        </Billboard>
      </FiberTooltip>
    }
    </>
  );
};

const InternalHouseArrow = ({nodes, materials, data, load, mode, setMode, setHouseView, beingViewed, setBeingViewed, setAzimuthalAngle}) => {
  const arrowRef = useRef();
  const houseView = useSelector((state) => state.houseView.filter((view) => view.id === data.house_view_id)[0]);
  const houseType = useSelector((state) => state.houseTypes.filter((type) => type.id === houseView?.houseTypeId)[0]);
  const handleClick = (e) => {
    e.stopPropagation();
    if (mode === 'house-view') {
      if (houseView) {
        const [ getPanorama ] = houseView.options.filter((option) => option.id === data?.pinOptionId);
        setAzimuthalAngle(getPanorama?.azimuthalAngle ?? 0);
        if (getPanorama.panoramas) {
          const files = getPanorama.panoramas.map((panorama) => panorama.url.replace('/', ''));
          setBeingViewed(getPanorama);
          load(files, {data, houseView, houseType});
        }
      }
    } else if (mode === 'delete') {
      console.log('delete');
      load([], {data, houseView, houseType});
    }
  }

  return (
    <>
    <ambientLight intensity={0.2} />
    {nodes && materials &&
      <FiberTooltip text={beingViewed ? houseView.options.filter((f)=> f.id === data.pinOptionId)[0]?.name : houseType?.name}>
        <Billboard follow={true} lockX={true} lockY={true} lockZ={true}>
          <group ref={arrowRef} dispose={null} onClick={(e)=>handleClick(e)} rotation={[0, degToRad(-30), 0]}>
            <mesh geometry={nodes.icon_chevron.geometry} material={materials['Material.002']} position={[0.034, 0.3, 0.029]} rotation={[0, 0.45, Math.PI / 2]} scale={[1, 1, 0.01]} />
            <mesh geometry={nodes.icon_chevron001.geometry} material={materials['Material.002']} position={[0.02, 0.3, -0.19]} rotation={[0, 0.45, Math.PI / 2]} scale={[1, 1, 0.01]} />
            <mesh geometry={nodes.Torus052.geometry} material={materials.Material} position={[-0.004, 0, -0.06]} rotation={[0, 0.45, 0]} scale={[1, 1, 0.34]} />
          </group>
        </Billboard>
      </FiberTooltip>
    }
    </>
  );
};

export default PanoramaViewer
