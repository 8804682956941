import { useEffect } from 'react';
import { usePreviewProperties } from '../../contexts/brandingContext';
import { makeCssColor } from '../../../../utils';
import { QRCodeSVG } from 'qrcode.react';
import './styles.scss';

const PreviewMortgageCalculator = ({ content, codeUrl, codeText, coverMedia, mediaType,
  qrCodeHeading1, qrCodeHeading2, qrCodeUrl1, qrCodeUrl2
 }) => {
  // ask the preview to give us its properties
  const properties = usePreviewProperties();
  useEffect(() => {}, [content, codeUrl, codeText, coverMedia]);
  const detectType = (input) => {
    if (typeof input === 'string') {
      return 'string';
    }
    if (typeof input === 'object') {
      if (Array.isArray(input)) {
        return 'array';
      }
      if (input !== null && !Array.isArray(input)) {
        return 'object';
      }
    }
    return 'unknown';
  }
  return (
    <>
      <style>
        span.highlight {`{ color: ${properties.branding.tertiary}}`}
      </style>
      <div
        className='preview-interest'
        style={{
          background: makeCssColor(properties.branding.section),
        }}
      >
        <div className='container'>
          <div className='flex-grid'>
            <div style={{ width: '100%' }}>
              <h1>Mortgage Calculator</h1>
              <div className='preview-media'>
                {(coverMedia && mediaType == 'image') &&
                  <img src={detectType(coverMedia) == 'string' ? coverMedia : coverMedia.url} alt="mortgage-calculator-media" />
                }
                {(coverMedia && mediaType == 'video') && 
                  <video src={detectType(coverMedia) == 'string' ? coverMedia : coverMedia.url} autoPlay muted loop />
                }
              </div>
              <hr style={{ borderColor: `${properties.branding.line}` }} />
              <p className='scroll-container' dangerouslySetInnerHTML={{ __html: content }} />
              <hr style={{ borderColor: `${properties.branding.line}` }} />
              <p dangerouslySetInnerHTML={{ __html: codeText }} />
              {/* {codeUrl &&
                <QRCodeSVG value={codeUrl} style={{ width: '6rem', height: '6rem' }}/>
              } */}
              <div className='qr-codes'>
                <div>
                  <h4>{qrCodeHeading1}</h4>
                  {qrCodeUrl1 &&
                    <QRCodeSVG value={qrCodeUrl1} style={{ width: '4rem', height: '4rem' }}/>
                  }
                </div>
                <div>
                  <h4>{qrCodeHeading2}</h4>
                  {qrCodeUrl2 &&
                    <QRCodeSVG value={qrCodeUrl2} style={{ width: '4rem', height: '4rem' }}/>
                  }
                </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreviewMortgageCalculator;
