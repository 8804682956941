import store from "../../store";
import { beablooApi } from "../../utils";

const download = async (filename) => {
  const { general: { currentDevelopment } } = store.getState();
  const response = await beablooApi({
    method: 'get',
    route: `/developments/${currentDevelopment.id}/blobStorage/download?blobName=${filename}`,
  });

  if (!response.success) {
    throw new Error('unable to download file')
  }

  return response.data;
};

const deleteSingle = async(url) => {
  const { general: { currentDevelopment } } = store.getState();
  const response = await beablooApi({
    method: 'delete',
    route: `/developments/${currentDevelopment.id}/blobStorage/delete?url=${url}`,
  });

  if (!response.success) {
    throw new Error('unable to delete file')
  }

  return response.data;
}

const deleteMultiple = async(urls) => {
  const { general: { currentDevelopment } } = store.getState();
  const response = await beablooApi({
    method: 'delete',
    route: `/developments/${currentDevelopment.id}/blobStorage/delete-multiple`,
    payload: {urls: urls}
  });

  if (!response.success) {
    throw new Error('unable to delete multiple file')
  }

  return response.data;
}

const uploadSingle = async(fileName, file) => {
  const { general: { currentDevelopment } } = store.getState();
  const response = await beablooApi({
    method: 'POST',
    route: `/developments/${currentDevelopment.id}/blobStorage/upload`,
    payload: { name: fileName, fileContent: file }
  });

  if (!response.success) {
    throw new Error('unable to upload file')
  }

  return response.data;
}

const genrateUrls = async (urls) => {
  const { general: { currentDevelopment } } = store.getState();
  const response = await beablooApi({
    method: 'POST',
    route: `/developments/${currentDevelopment.id}/blobStorage/generate-urls`,
    payload: {urls: urls}
  });

  if (!response.success) {
    throw new Error('unable to generate urls')
  }
  return response.data;
}

 
const blobServices = {
  download,
  deleteSingle,
  deleteMultiple,
  uploadSingle,
  genrateUrls
}

export default blobServices;