/* eslint-disable eqeqeq */
import './styles.scss';
import { Form, Heading, SignTouchPreview, Toggle } from '../../components';
import { PreviewIndividualHouseType } from '../../components/SignTouchPreview/pages';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { ApiError, beablooApi } from '../../utils';
import { OptionSelector } from './components';
import { StagedOptionsProvider } from './contexts/stagedOptions';
import { addNotification, addError } from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { addHouseType, updateHouseType } from '../../store/actions/houseTypes';
import addPending from '../../utils/addPending';
import removePending from '../../utils/removePending';
import { useEffect } from 'react';
import { houseTypeBedroomOptions, propertyTypeOptions } from './CreateHouseType.options';
import { LabeledToggle } from '../../components/Toggle/LabeledToggle';

const CreateHouseType = (props) => {
  const { developmentId, houseTypeId } = useParams();
  const { currentDevelopment } = useSelector((state) => state.general);
  const { integrations } = useSelector((state) => state.integrations);


  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  // locate a saved house if any
  const houseType = useSelector((state) => state.houseTypes).find(
    (house) => house.id == houseTypeId,
  );

  // house state
  const [name, setName] = useState(null);
  const [type, setType] = useState(null);
  const [bedrooms, setBedrooms] = useState(null);
  const [description, setDescription] = useState(null);
  const [about, setAbout] = useState(null);
  const [images, setImages] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [price, setPrice] = useState(null);
  const [options, setOptions] = useState([]);
  const [floorplan, setFloorplan] = useState(null);
  const [bathrooms, setBathrooms] = useState(null);
  const [squareFeet, setSquareFeet] = useState(null);
  const [flyover, setFlyover] = useState(null);
  const [matterportUrl, setMatterportUrl] = useState('');

  // api errors
  const [errors, setErrors] = useState([]);

  // are we editing
  const [isEdit, setIsEdit] = useState(false);
  // should we copy
  const [isCopy, setIsCopy] = useState(false);

  // can we save 
  const [canSave, setSave] = useState(true);

  const [saved, setSaved] = useState(false);
  //promotions states
  const [depositPaid, setDepositPaid]= useState(false);
  const [partExchange, setPartExchange]= useState(false);
  const [featurePlot, setFeaturePlot]= useState(false);

  // handle form change just feeds houseData
  const handleFormChange = (data) => {
    if (data.name) {
      setName(data.name);
    }
    if (data.type) {
      setType(data.type);
    }
    if (data.bedrooms) {
      setBedrooms(parseInt(data.bedrooms));
    }
    if (data.description) {
      setDescription(data.description);
    }
    if (data.about) {
      setAbout(data.about);
    }
    if (data.images) {
      setImages(data.images);
    }
    if (data.thumbnail) {
      setThumbnail(data.thumbnail);
    }
    if (data.price) {
      setPrice(data.price);
    }
    if (data.floorplan) {
      setFloorplan(data.floorplan);
    }
    if (data.bathrooms) {
      setBathrooms(data.bathrooms);
    }
    if (data.squareFeet) {
      setSquareFeet(data.squareFeet);
    }
    if (data.flyover) {
      setFlyover(data.flyover);
    } 
    if (data.matterportUrl) {
      setMatterportUrl(data.matterportUrl);
    }
  };

  // format an existing HouseType into the state of the component
  const handleExistingHouseType = (data) => {
    if (data.name) {
      setName(isCopy ? `${data.name} - Copy` : data.name);
    }
    if (data.details) {
      setBedrooms(data.details.bedrooms);
      setType(data.details.type);
      if (data.details.bathrooms) {
        setBathrooms(data.details.bathrooms);
      }
      if (data.details.squareFeet) {
        setSquareFeet(data.details.squareFeet);
      }
    }
    if (data.images) {
      setImages(data.images);
    }
    if (data.description !== description) {
      setDescription(data.description);
    }
    if (data.price) {
      setPrice(data.price);
    }
    if (data.thumbnail) {
      setThumbnail(data.thumbnail);
    }
    if (data.about) {
      setAbout(data.about);
    }
    // options are handled via context
    if (data.options) {
      setOptions(data.options);
    }
    if (data.floorplan) {
      setFloorplan(data.floorplan);
    }
    if (data.flyover) {
      setFlyover(data.flyover);
    } 
    if (data.matterportUrl) {
      setMatterportUrl(data.matterportUrl);
    }
    if(data.depositPaid) {
      setDepositPaid(data.depositPaid)
    }
    if(data.partExchange) {
      setPartExchange(data.partExchange)
    }
    if(data.featurePlot) {
      setFeaturePlot(data.featurePlot)
    }
  };

  const handleSubmit = async () => {
    // Stop multiple saves
    setSave(false);
    // build a payload to match the HouseType model:
    // https://signtouch-api-docs.prodigitalsignage.co.uk/models/housetype/
    const payload = {
      name: name,
      description: description,
      about: about,
      thumbnail: thumbnail,
      images: images,
      details: {
        type: type,
        bedrooms: bedrooms,
      },
      price: price,
      options: options,
      matterportUrl: matterportUrl,
      featurePlot:featurePlot,
      depositPaid:depositPaid,
      partExchange:partExchange
    };

    if (flyover) {
      payload.flyover = flyover;
    }

    if (bathrooms) {
      payload.details.bathrooms = bathrooms;
    }
    if (squareFeet) {
      payload.details.squareFeet = parseFloat(squareFeet);
    }

    if (floorplan) {
      payload.floorplan = floorplan;
    }
    if (matterportUrl) {
      payload.matterportUrl = matterportUrl;
    }

    // send house model to API
    try {
      // notify the user saving is in progress
      const pendingToast = addPending(`saving ${name}`);

      // await the APIs response
      let response;
      if (isEdit) {
        // if this is a copy then create a new houseType otherwise update the existing one
        if (isCopy) {
          response = await beablooApi({
            method: 'POST',
            route: `/developments/${developmentId}/houseTypes/create`,
            payload: payload,
          });
        } else {
          response = await beablooApi({
            method: 'PUT',
            route: `/developments/${developmentId}/houseTypes/${houseTypeId}/update`,
            payload: payload,
          });
        }
      } else if (!isEdit) {
        // create a new house type
        response = await beablooApi({
          method: 'POST',
          route: `/developments/${developmentId}/houseTypes/create`,
          payload: payload,
        });
      }

      // remove the pending notification
      removePending(pendingToast);
      setSave(true);

      if (!response.success) {
        throw new ApiError(
          `Failed to ${isEdit ? 'update' : 'create'} House Type`,
          response.errors,
        );
      }

      setSaved(true);
      // house type was created notify the user
      addNotification(
        `${name} was ${
          isEdit ? (isCopy ? 'created' : 'updated') : 'created'
        } successfully`,
      );
      // if this is an edit then update the houseType
      if (isEdit) {
        dispatch(
          updateHouseType({
            ...response.data,
            belongsTo: currentDevelopment.id,
          }),
        );
        return;
      }
      // redirect to all houses
      navigate(`/developments/${developmentId}/houseTypes`);
      // update the global state with the new house type
      dispatch(
        addHouseType({ ...response.data, belongsTo: currentDevelopment.id }),
      );
    } catch (e) {
      // failed to create house type handle the error
      addError(e.message);
      setSave(true);

      // pass the errors to state
      setErrors(e.errors);
    }
  };

  // check to see if this is an edit
  useEffect(() => {
    if (!houseTypeId) {
      return;
    }

    // the pathname coontains /copy we know that this should be treated as a copy
    if (location.pathname.includes('/copy')) {
      setIsCopy(true);
    }

    // tell form to edit
    setIsEdit(true);

    // user wants to edit the houseType
    // load the data into state
    handleExistingHouseType(houseType);
  }, []);

  return (
    <StagedOptionsProvider
      key={`staged-options-from-state-${isEdit ? 'true' : 'false'}`}
      value={options}
    >
      <div className='page-padding'>
        <div className='create-house-type'>
          <Heading
            label={`${
              isEdit ? (isCopy ? 'Copy' : 'Edit') : 'Create'
            } a house type`}
          />
          <div className='create-house-type-form'>
            <Form
              key={`house-type-form-edit-${isEdit ? 'true' : 'false'}`}
              inputs={[
                {
                  name: 'name',
                  label: 'House Name',
                  type: 'text',
                  validation: { length: 1024 },
                  initialValue: name,
                },
                {
                  name: 'bedrooms',
                  label: 'Bedrooms',
                  type: 'select',
                  options: houseTypeBedroomOptions,
                  initialValue: bedrooms,
                },
                {
                  name: 'bathrooms',
                  label: 'Bathrooms',
                  type: 'number',
                  initialValue: bathrooms,
                },
                {
                  name: 'squareFeet',
                  label: 'Square Feet',
                  type: 'number',
                  initialValue: squareFeet,
                },
                {
                  name: 'type',
                  label: 'Property Type',
                  type: 'select',
                  options: propertyTypeOptions,
                  initialValue: type,
                },
                {
                  name: 'images',
                  label: 'Images',
                  type: 'multi-image',
                  initialValue: images ? images : [],
                },
                {
                  name: 'floorplan',
                  label: 'Floor Plan',
                  type: 'multi-image',
                  initialValue: floorplan ? floorplan : [],
                },
                {
                  name: 'price',
                  label: 'Price',
                  type: 'money',
                  initialValue: price,
                },
                {
                  name: 'thumbnail',
                  label: 'Thumbnail',
                  type: 'single-image',
                  initialValue: thumbnail,
                },
                {
                  name: 'description',
                  label: 'caption',
                  type: 'text',
                  initialValue: description,
                },
                {
                  name: 'about',
                  label: 'About',
                  type: 'textarea',
                  initialValue: about,
                },
                {
                  name: 'flyover',
                  label: 'Flyover',
                  type: 'single-image',
                  isVideo: true,
                  initialValue: flyover,
                },
                {
                  name: 'matterportUrl',
                  label: 'Matterport URL',
                  type: 'text',
                  initialValue: matterportUrl,
                },
              ]}
              onChange={handleFormChange}
              canSubmit={canSave}
              errors={errors}
              saved={saved}
            />
          </div>
           <div>
              <h3 className='promotion-label'>Promotions</h3>
               <LabeledToggle
                label="Deposit Paid"
                initialValue={depositPaid}
                onChange={(e) => setDepositPaid(e)}
              />
               <LabeledToggle
                label="Part Exchange"
                initialValue={partExchange}
                onChange={(e) => setPartExchange(e)}
              />
            </div>
            <div className='feature-housetype'>
              <h3 className='promotion-label'>FEATURED HOUSE TYPE</h3>
              <Toggle onChange={(e) => setFeaturePlot(e)} initialValue={featurePlot} />
            </div>

          <OptionSelector onChange={(data) => setOptions(data)} />

          {canSave ? (
            <div className='custom-submit'>
              <p onClick={handleSubmit}>Save</p>
            </div>
          ) : (
            <div className='custom-submit'>
              <p>Saving...</p>
            </div>
          )}

          <div style={{ position: 'fixed', right: '10vw', top: '10%' }}>
            <SignTouchPreview>
              <PreviewIndividualHouseType
                key={`preview-show-edit-${isEdit ? 'true' : 'false'}`}
                name={name}
                type={type}
                description={description}
                about={about}
                images={images}
                price={price}
                details={{
                  bathrooms: bathrooms,
                  squareFeet: squareFeet,
                  bedrooms: bedrooms,
                  type: type,
                }}
              />
            </SignTouchPreview>
          </div>
        </div>
      </div>
    </StagedOptionsProvider>
  );
};

export default CreateHouseType;
