import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router';
import { useParams, useNavigate, Navigate } from 'react-router-dom';
import { MainLayout } from '../../layouts';
import { ProtectedRoute } from '../../middleware';

import {
  CreateHouseType,
  CreatePlot,
  DevelopmentMapEditor,
  DevelopmentOverview,
  HouseTypes,
  PlotsTable,
  SetupWizard,
  OptionsOverview,
  CreateOption,
  EditOptions,
  CreateOptionCategory,
  CreateOptionType,
  EditOptionCategory,
  EditOptionType,
  CreateGallery,
  ParentProperties,
  StaticPagesList,
  Modules,
  EditWelcomePage,
  EditHomePage,
  EditContactPage,
  EditRegisterInterest,
  DevelopmentMetadata,
  PromotionsAndSalesMethods,
  PlayerUI,
  Integrations,
  Matterport,
  MultiFloorConfigurationPage,
  CreateFloorPage,
  MultiFloorMapPage,
  EditFloorPage,
  BasicMapConfigurationPage,
  CreatePlotPage,
  EditPlotPage,
  CreateHouseViewFloorPage,
  EditHouseViewFloorPage,
  FloorOptionsPage,
  AnalyticsPage,
  StatusCategorisation
} from '../../pages';
import { initialise } from '../../store/actions/initialisation';
import {
  applicationUnready,
  setCurrentDevelopment,
} from '../../store/actions/general';
import { EditPlot, Loading } from '../';
import TransportLinks from '../../pages/TransportLinks';
import EditPromotionsAndSalesMethods from '../../pages/EditPromotionsAndSalesMethods';
import CreatePromotionsAndSalesMethods from '../../pages/CreatePromotionsAndSelesMethods';
import { LocalInformation } from '../../pages';
import { DesignAdvice } from '../../pages';
import { CreateDesignAdvice } from '../../pages';
import { HouseViewConfigration } from '../../pages/HouseViewConfigration/HouseViewConfigration';
import { MultiFloorHouseViewConfigrationPage } from '../../pages/MultiFloorHouseViewConfigrationPage';
import { CreateOptions, EditFloorOptions } from '../../pages/FloorOptionsPage/components';
import { HouseViewFloorMap } from '../../pages/HouseViewFloorMap';
import EditMortrageCalculator from '../../pages/EditMortrageCalculator';
import StreetViewConfiguration from '../../pages/StreetViewConfiguration/StreetViewConfiguration';
import StreetViewMap from '../../pages/StreetViewMap/StreetViewMap';

const AppRoutes = (props) => {
  const { developmentId } = useParams();
  const authentication = useSelector((state) => state.authentication);
  const developments = useSelector((state) => state.developments);
  const general = useSelector((state) => state.general);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const development = developments.find((d) => `${d.id}` === developmentId);

  useEffect(() => {
    if (!authentication.loggedin) return;
    if (general.ready) dispatch(applicationUnready());
    if (development) {
      dispatch(setCurrentDevelopment(development));
    }
    dispatch(initialise());
  }, [dispatch, developmentId]);

  // redirect the app if load error
  const { loadError } = useSelector((state) => state.general);
  useEffect(() => {
    if (loadError) {
      navigate('/');
    }

    if (!authentication.loggedin) {
      navigate('/login')
    }
  }, [loadError, authentication]);

  if (!general.ready) return <Loading />;

  return (
    <MainLayout>
      <Routes>
        <Route
          exact
          path='/'
          element={<ProtectedRoute><DevelopmentOverview /></ProtectedRoute>}
        />
        <Route
          exact
          path='/properties'
          element={<ProtectedRoute><ParentProperties /></ProtectedRoute>}
        />
        <Route
          exact
          path='/analytics'
          element={<ProtectedRoute><AnalyticsPage /></ProtectedRoute>}
        />
        <Route
          exact
          path='/map-editor'
          element={<ProtectedRoute><DevelopmentMapEditor /></ProtectedRoute>}
        />
        <Route
          exact
          path='/basic-map/configuration'
          element={<ProtectedRoute><BasicMapConfigurationPage /></ProtectedRoute>}
        />
        <Route
          exact
          path='/plots'
          element={<ProtectedRoute><PlotsTable /></ProtectedRoute>}
        />
        <Route
          exact
          path='/plots/create'
          element={<ProtectedRoute><CreatePlotPage /></ProtectedRoute>}
        />
        <Route
          exact
          path='/plots/:plotId/edit'
          element={<ProtectedRoute><EditPlotPage /></ProtectedRoute>}
        />
        <Route
          exact
          path='/houseTypes'
          element={<ProtectedRoute><HouseTypes /></ProtectedRoute>}
        />
        <Route
          exact
          path='/houseTypes/create'
          element={<ProtectedRoute><CreateHouseType /></ProtectedRoute>}
        />
        <Route
          exact
          path='/houseTypes/:houseTypeId'
          element={<ProtectedRoute><CreateHouseType /></ProtectedRoute>}
        />
        <Route
          exact
          path='/houseTypes/:houseTypeId/copy'
          element={<ProtectedRoute><CreateHouseType /></ProtectedRoute>}
        />
        <Route
          exact
          path='/optionsOverview'
          element={<ProtectedRoute><OptionsOverview /></ProtectedRoute>}
        />
        <Route
          exact
          path='/options/:optionId'
          element={<ProtectedRoute><EditOptions /></ProtectedRoute>}
        />
        <Route
          exact
          path='/options/create'
          element={<ProtectedRoute><EditOptions /></ProtectedRoute>}
        />
        <Route
          exact
          path='/setup'
          element={<ProtectedRoute><SetupWizard /></ProtectedRoute>}
        />
        <Route
          exact
          path='/status-categorisation'
          element={<ProtectedRoute><StatusCategorisation /></ProtectedRoute>}
        />
        <Route
          exact
          path='/optionTypes/:typeId/create'
          element={<ProtectedRoute><CreateOption /></ProtectedRoute>}
        />
        <Route
          exact
          path='/optionType/:typeId'
          element={<ProtectedRoute><EditOptionType /></ProtectedRoute>}
        />
        <Route
          exact
          path='/optionCategory/:categoryId/optionTypes/create'
          element={<ProtectedRoute><CreateOptionType /></ProtectedRoute>}
        />
        <Route
          exact
          path='/optionCategory/create'
          element={<ProtectedRoute><CreateOptionCategory /></ProtectedRoute>}
        />
        <Route
          exact
          path='/optionCategory/:categoryId'
          element={<ProtectedRoute><EditOptionCategory /></ProtectedRoute>}
        />
        <Route
          exact
          path='/galleries/create'
          element={<ProtectedRoute><CreateGallery /></ProtectedRoute>}
        />
        <Route
          exact
          path='/pages'
          element={<ProtectedRoute><StaticPagesList /></ProtectedRoute>}
        />
        <Route
          exact
          path='/modules'
          element={<ProtectedRoute><Modules /></ProtectedRoute>}
        />
        <Route
          exact
          path='/promotions-sales-methods/create'
          element={<ProtectedRoute><CreatePromotionsAndSalesMethods /></ProtectedRoute>}
        />
        <Route
          exact
          path='/promotions-sales-methods/:promotionId'
          element={<ProtectedRoute><EditPromotionsAndSalesMethods /></ProtectedRoute>}
        />
        <Route
          exact
          path='/promotions-sales-methods'
          element={<ProtectedRoute><PromotionsAndSalesMethods /></ProtectedRoute>}
        />
        <Route
          exact
          path='/design-advice'
          element={<ProtectedRoute><DesignAdvice /></ProtectedRoute>}
        />
        <Route
          exact
          path='/design-advice/:typeId/:adviceId'
          element={<ProtectedRoute><CreateDesignAdvice /></ProtectedRoute>}
        />

        <Route
          exact
          path='/local-information'
          element={<ProtectedRoute><LocalInformation /></ProtectedRoute>}
        />

        <Route
          exact
          path='/metadata'
          element={<ProtectedRoute><DevelopmentMetadata /></ProtectedRoute>}
        />
        <Route
          exact
          path='/transport-links'
          element={<ProtectedRoute><TransportLinks /></ProtectedRoute>}
        />
        <Route
          exact
          path='/pages/welcome'
          element={<ProtectedRoute><EditWelcomePage /></ProtectedRoute>}
        />
        <Route
          exact
          path='/pages/home'
          element={<ProtectedRoute><EditHomePage /></ProtectedRoute>}
        />
        <Route
          exact
          path='/pages/contact'
          element={<ProtectedRoute><EditContactPage /></ProtectedRoute>}
        />
        <Route
          exact
          path='/pages/register-interest'
          element={<ProtectedRoute><EditRegisterInterest /></ProtectedRoute>}
        />
        <Route
          exact
          path='/pages/mortgage-calculator'
          element={<ProtectedRoute><EditMortrageCalculator /></ProtectedRoute>}
        />
        <Route
          exact
          path='/playerui'
          element={<ProtectedRoute><PlayerUI /></ProtectedRoute>}
        />
        <Route 
          exact
          path='/integrations'
          element={<ProtectedRoute><Integrations /></ProtectedRoute>}
        />
        <Route 
          exact
          path='/integrations/matterport'
          element={<ProtectedRoute><Matterport /></ProtectedRoute>}
        />
        <Route 
          exact
          path='/multi-floor/configuration'
          element={<ProtectedRoute><MultiFloorConfigurationPage /></ProtectedRoute>}
        />
        <Route 
          exact
          path='/multi-floor/floors/create'
          element={<ProtectedRoute><CreateFloorPage /></ProtectedRoute>}
        />
        <Route 
          exact
          path='/multi-floor/floors/:floorId/edit'
          element={<ProtectedRoute><EditFloorPage /></ProtectedRoute>}
        />
        <Route 
          exact
          path='/multi-floor/map'
          element={<ProtectedRoute><MultiFloorMapPage /></ProtectedRoute>}
        />
        <Route
          exact
          path='/house-view/configuration'
          element={<ProtectedRoute><HouseViewConfigration /></ProtectedRoute>}
        />
        <Route
          exact
          path='/house-view/map/:houseId'
          element={<ProtectedRoute><HouseViewFloorMap /></ProtectedRoute>}
        />
        <Route 
          exact
          path='/house-view/multi-floor/configuration/:houseTypeId'
          element={<ProtectedRoute><MultiFloorHouseViewConfigrationPage /></ProtectedRoute>}
        />
        <Route 
          exact
          path='/house-view/multi-floor/configuration/:houseTypeId/create-floor'
          element={<ProtectedRoute><CreateHouseViewFloorPage /></ProtectedRoute>}
        />
        <Route 
          exact
          path='/house-view/multi-floor/configuration/:houseTypeId/edit-floor/:floorId'
          element={<ProtectedRoute><EditHouseViewFloorPage /></ProtectedRoute>}
        />
        <Route 
          exact
          path='/house-view/:houseTypeId/floors-configrations'
          element={<ProtectedRoute><EditHouseViewFloorPage /></ProtectedRoute>}
        />
        <Route 
          exact
          path='/house-view/floor-options/:houseId'
          element={<ProtectedRoute><FloorOptionsPage /></ProtectedRoute>}
        />
        <Route 
          exact
          path='/house-view/floor-options/:houseId/create-option/:floorId'
          element={<ProtectedRoute><CreateOptions /></ProtectedRoute>}
        />
        <Route 
          exact
          path='/house-view/floor-options/:houseId/edit-option/:floorId/:optionId'
          element={<ProtectedRoute><EditFloorOptions /></ProtectedRoute>}
        />
      {/*Street view routes */}
        <Route 
          exact
          path='/street-view/configuration'
          element={<ProtectedRoute><StreetViewConfiguration /></ProtectedRoute>}
        />
        <Route
          exact
          path='/street-view/configuration/:pinId'
          element={<ProtectedRoute><StreetViewMap /></ProtectedRoute>}
        />
      </Routes>
    </MainLayout>
  );
};

export default AppRoutes;

// {/* <ProtectedRoute exact path='/branding' element={<ProtectedRoute></ProtectedRoute>Branding} /> */}