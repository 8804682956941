/* eslint-disable eqeqeq */
import { sortAlphabetically } from '../../utils';
import schema from '../schemas/_houseTypes';

const houseTypesReducer = (state = schema, action) => {
  switch (action.type) {
    case 'SET_HOUSE_TYPES':
      return [...action.data];

    case 'ADD_HOUSE_TYPE':
      return sortAlphabetically([...state, action.data], 'name');

    case 'UPDATE_HOUSE_TYPE':
      return sortAlphabetically([
        ...state.filter((house) => house.id != action.data.id),
        action.data,
      ], 'name');

    case 'DELETE_HOUSE_TYPE':
      return sortAlphabetically([...state.filter((house) => house.id != action.data.id)], 'name');

    default:
      return [...state];
  }
};

export default houseTypesReducer;
