import React, { useEffect, useState } from 'react'
import './styles.scss'
import { SidebarSeperator } from '../../../../components'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import { DeleteIcon } from '../../../../components/Icons';
import { Box } from '@mui/material';
import { houseViewService } from '../../../../lib/signtouch';
import { useDispatch, useSelector } from 'react-redux';
import { addError } from '../../../../utils';
import { updateHouseView } from '../../../../store/actions/houseView';
import addPending from '../../../../utils/addPending';
import removePending from '../../../../utils/removePending';

export const FloorOptionsList = ({houseView, handleEditPin, currentFloor}) => {

  const dispatch = useDispatch();
  const [tab, setTab] = useState('unassigned');
  const [selectedOption, setSelectedOption] = useState({});
  const [inOpreation, setInOperation] = useState(false);
  const [ houseType ] = useSelector(state => state.houseTypes.filter(ht => ht.id === houseView.houseTypeId));
  
  const handleDelete = async (option) => {
    setInOperation(true);
    const pending = addPending(`deleting ${option.name}`);
    // this will update new the exsisting option to unassigned
    const optionToUpdate = {
      ...option,
      placed: false,
      coordinates: [],
    };

    // here we will get all other option than current option
    const allOtherOptions = houseView.options.filter(o => o.id !== option.id);

    // here we will merge the updated option with all other options
    const updatedOptions = [
      ...allOtherOptions,
      optionToUpdate
    ];

    // here we will update the house view with updated options
    const payload = {
      ...houseView,
      options: updatedOptions,
    }

    await houseViewService.updateHouseView(payload, houseView.id)
    .then((r) => {
      dispatch(updateHouseView(r));
      removePending(pending);
      setInOperation(false);
    }).catch((e) => {
      addError('unable to delete option');
      removePending(pending);
      setInOperation(false);
    });
    

  }

  useEffect(() => {}, [tab, currentFloor])
  return (
    <div className='action-pane-map'>
      <h1 style={{ marginBottom: '5px' }}>
        {houseType?.name}
        {currentFloor &&
          <>
            <br/><span className={'blue-color medium'}>{currentFloor.name}</span>
          </>
          }
      </h1>
      <h4 style={{ marginTop: 0 }}>
        Select an unassigned room / area to place on the map
      </h4>
      <SidebarSeperator styles={{width: '100%'}}/>
      <div className={tab === 'unassigned'? 'selected-dropdown' : '' }>
        <div className='expandable' onClick={() => setTab('unassigned')}>
          <label>unassigned</label>
          {tab === 'unassigned'
          ? <ChevronUpIcon className='expandable-icon' />
          : <ChevronDownIcon className='expandable-icon' /> }
        </div>
        <div className='overflow-content-map'>
          {houseView.options && houseView.options.map( option => (
            <>
            {(!option.placed && (currentFloor && option.floorId === currentFloor.id)) && 
              <div key={option.id}  className={tab === 'unassigned'  ? 'expandable-content show' : 'expandable-content hide'}>
                <div
                  className={option.id == selectedOption.id  ? 'selected floor' : 'floor'}
                  style={{ cursor: 'pointer' }}>
                    <p onClick={() => {
                        if (!inOpreation) {
                          setSelectedOption(option);
                          handleEditPin(option);
                        }
                      }}
                    >{option.name}</p>
                  </div>
              </div>}
            </>
          ))}
        </div>
      </div>
      <SidebarSeperator styles={{width: '100%'}}/>
      <div className={tab === 'assigned'? 'selected-dropdown' : '' }>
        <div className='expandable' onClick={() => setTab('assigned')}>
          <label>assigned</label>
          {tab === 'assigned'
          ? <ChevronUpIcon className='expandable-icon' />
          : <ChevronDownIcon className='expandable-icon' /> }
        </div>
        <div className='overflow-content-map'>
          {houseView.options && houseView.options.map( option => (
            <>
            {(option.placed && (currentFloor && option.floorId === currentFloor.id)) && 
              <div key={option.id}  className={tab === 'assigned'  ? 'expandable-content show' : 'expandable-content hide'}>
                <div
                  className={option.id == selectedOption.id  ? 'selected floor' : 'floor'}
                  style={{ cursor: 'pointer' }}>
                    <p
                      onClick={() => {
                        if (!inOpreation) {
                          setSelectedOption(option);
                          handleEditPin(option);
                        }
                      }}
                    >{option.name}</p>
                    <div className='delete-icon' onClick={() => !inOpreation ? handleDelete(option) : ''}>
                      <DeleteIcon/>
                    </div>
                </div>
              </div>}
            </>
          ))}
        </div>
      </div>
      <SidebarSeperator styles={{width: '100%'}}/>
    </div>
  )
}
 