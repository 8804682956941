import { Form, Saving, Heading } from '../../components';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { beablooApi } from '../../utils';
import { useState } from 'react';
import { addError } from '../../utils';
import { addNotification } from '../../utils';
import { SignTouchPreview } from '../../components';
import { PreviewMortgageCalculator } from '../../components/SignTouchPreview/pages';
import './index.scss';
import addPending from '../../utils/addPending';
import removePending from '../../utils/removePending';
import { setMortgageCalculator } from '../../store/actions/mortgage-calculator';

const EditMortrageCalculator = () => {
  const { developmentId } = useParams();
  const [errors, setErrors] = useState([]);
  const [saving, setSaving] = useState(false);

  const { content, codeText, codeUrl, coverMedia, mediaType, qrCodeHeading1, qrCodeHeading2, qrCodeUrl1, qrCodeUrl2
   } = useSelector(
    (state) => state.mortgageCalculator,
  );

  const [editedContent, setContent] = useState(content);
  const [editedCodeText, setCodeText] = useState(codeText);
  const [editedCodeUrl, setCodeUrl] = useState(codeUrl);
  const [editedMedia, setMedia] = useState(coverMedia);
  const [editiedMediaType, setMediaType] = useState(mediaType);
  //
  const [editedQrCodeHeading1, setQrCodeHeading1] = useState(qrCodeHeading1);
  const [editedQrCodeHeading2, setQrCodeHeading2] = useState(qrCodeHeading2);
  const [editedQrCodeUrl1, setQrCodeUrl1] = useState(qrCodeUrl1);
  const [editedQrCodeUrl2, setQrCodeUrl2] = useState(qrCodeUrl2);


  // const socket = useSocket();
  const dispatch = useDispatch();

  const handleSubmit = async (data) => {
    // trigger spinner
    setSaving(true);
    let pendingId = addPending('Saving...');

    const payload = {
      content: editedContent,
      codeUrl: editedCodeUrl,
      codeText: editedCodeText,
      coverMedia: editedMedia,
      mediaType: editiedMediaType,
      qrCodeHeading1 : data.qrCodeHeading1 !== undefined  ? data.qrCodeHeading1 : editedQrCodeHeading1,
      qrCodeHeading2 : data.qrCodeHeading2 !== undefined  ? data.qrCodeHeading2 : editedQrCodeHeading2,
      qrCodeUrl1 : data.qrCodeUrl1 !== undefined  ? data.qrCodeUrl1 : editedQrCodeUrl1,
      qrCodeUrl2 : data.qrCodeUrl2 !== undefined  ? data.qrCodeUrl2 : editedQrCodeUrl2,
    };

    await beablooApi({
      method: 'PUT',
      route: `/developments/${developmentId}/pages/mortgageCalculator/update`,
      payload: payload,
    }).then((r) => {
      removePending(pendingId);
      if (!r.success) {
        setSaving(false);
        addError(r.message);
        setErrors(r.errors);
      } else {
        setSaving(false); // stop loading animation
        dispatch(setMortgageCalculator()); // update the redux state
        addNotification('Mortgage Calculator page has been updated.');
      }
    });
  };

  const handleChange = (data) => {
    if (data.content) {
      setContent(data.content);
    }

    if (data.codeText) {
      setCodeText(data.codeText);
    }

    if (data.codeUrl) {
      setCodeUrl(data.codeUrl);
    }
    if (data.qrCodeHeading1) {
      setQrCodeHeading1(data.qrCodeHeading1);
    }
    if (data.qrCodeHeading2) {
      setQrCodeHeading2(data.qrCodeHeading2);
    }
    if (data.qrCodeUrl1) {
      setQrCodeUrl1(data.qrCodeUrl1);
    }
    if (data.qrCodeUrl2) {
      setQrCodeUrl2(data.qrCodeUrl2);
    }


    if (data.coverMedia) {
      setMedia(data.coverMedia);
      if (typeof data.coverMedia == 'string') {
        if (data.coverMedia.includes('image')) {
          setMediaType('image');
        } else if (data.coverMedia.includes('video')) {
          setMediaType('video');
        }
      }
    }
  };

  return (
    <div className='create-option page-padding'>
      <div className={'form-preview-container'}>
        <Heading label={`Edit Mortgage Calculator Page`} />

        <Form
          errors={errors}
          onChange={handleChange}
          onSubmit={handleSubmit}
          canSubmit={!saving}
          inputs={[
            {
              name: 'content',
              type: 'textarea',
              label: 'Main Content',
              initialValue: editedContent,
              bgType: 'section',
            },
            {
              name: 'codeText',
              type: 'textarea',
              label: 'Code Content',
              initialValue: editedCodeText,
              bgType: 'section',
            },
            {
              name: 'coverMedia',
              label: 'Use ',
              type: 'single-image',
              switch: true,
              mediaType: editiedMediaType,
              initialValue: editedMedia,
            },
            {
              name: 'qrCodeHeading1',
              type: 'text',
              label: 'QR Code 1 Location',
              initialValue: editedQrCodeHeading1,
            },
            {
              name: 'qrCodeUrl1',
              type: 'text',
              // label: 'QR Code 1 Location',
              initialValue: editedQrCodeUrl1,
            },

            {
              name: 'qrCodeHeading2',
              type: 'text',
              label: 'QR Code 2 Location',
              initialValue: editedQrCodeHeading2,
            },
            {
              name: 'qrCodeUrl2',
              type: 'text',
              // label: 'QR Code Location',
              initialValue: editedQrCodeUrl2,
            },
          ]}
        />
      </div>
      <div style={{ position: 'fixed', right: '10vw', top: '10%', zIndex: 10 }}>
        <SignTouchPreview>
          <PreviewMortgageCalculator
            content={editedContent ? editedContent : content}
            codeUrl={editedCodeUrl ? editedCodeUrl : codeUrl}
            codeText={editedCodeText ? editedCodeText : codeText}
            coverMedia={editedMedia ? editedMedia : coverMedia}
            mediaType={editiedMediaType ? editiedMediaType : mediaType}
            qrCodeHeading1={editedQrCodeHeading1 ? editedQrCodeHeading1 : qrCodeHeading1}
            qrCodeHeading2={editedQrCodeHeading2 ? editedQrCodeHeading2 : qrCodeHeading2}
            qrCodeUrl1={editedQrCodeUrl1 ? editedQrCodeUrl1 : qrCodeUrl1}
            qrCodeUrl2={editedQrCodeUrl2 ? editedQrCodeUrl2 : qrCodeUrl2}
          />
        </SignTouchPreview>
      </div>
    </div>
  );
};

export default EditMortrageCalculator;
