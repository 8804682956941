import './styles.scss';

const Heading = (props) => {
  return (
    <div className='heading' style={{ ...props.styles }}>
      <h3 dangerouslySetInnerHTML={{ __html: props.label }} style={{ ...props.headingStyles }}/>
    </div>
  );
};

export default Heading;
