import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Heading } from '../../components';
import './styles.scss';
import { useEffect, useState } from 'react';
import DeleteModal from '../../components/DeleteModal';
import { ReactComponent as DeleteIcon } from '../../assets/icons/trash.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/create.svg';
import { shouldTextBeBlack } from '../../utils';

const PlotsTable = (props) => {
  const plots = useSelector((state) => state.plots);
  const houseTypes = useSelector((state) => state.houseTypes);
  const currentDevelopment = useSelector(
    (state) => state.general.currentDevelopment,
  );
  const { branding } = useSelector((state) => state.properties);
  let { map } = branding;
  const allowed = ['available', 'sold', 'reserved'];

  let statusTypesAsOptions = [];
  Object.keys(map)
  .filter(key => (!key.endsWith("label") && !key.endsWith("category")))
  .reduce((obj, key) => {
    obj = {label:map[key+"label"], value:key, color:map[key]};
    statusTypesAsOptions[key] = obj;
  }, {});

  const { developmentId } = useParams();

  const [sortedPlots, setSortedPlots] = useState([]);
  const [sortBy, setSortBy] = useState({});

  const [deleteModal, setDeleteModal] = useState({});

  useEffect(() => {
    let newSorted = [];

    for (let i = 0; i < plots.length; i++) {
      newSorted.push(plots[i]);
    }

    setSortedPlots(newSorted);
  }, [plots]);

  const makeIdHouseName = (id) => {
    let houseType = houseTypes.find((type) => `${type.id}` === `${id}`);

    if (!houseType) {
      houseType = { name: 'House Type Error' };
    }

    return houseType.name;
  };

  const calcPlotBedrooms = (id) => {
    const houseType = houseTypes.find((house) => house.id == id);

    if (!houseType) {
      return 0;
    }

    if (!houseType.details) {
      return 0;
    }

    if (!houseType.details.bedrooms) {
      return 0;
    }

    return houseType.details.bedrooms;
  };

  const formatCurrency = (price) => {
    if (!price) return;

    try {
      return `£${new Intl.NumberFormat('en-GB', {
        stlye: 'currency',
        currency: 'GBP',
      }).format(parseInt(price.amount))}`;
    } catch (error) {
      return 'Price Error';
    }
  };

  const capitalize = (string) => {
    if (!string) return;
    return String(string.charAt(0).toUpperCase() + string.slice(1));
  };

  const makeSort = (sortBy, direction) => {
    setSortedPlots([]);

    if (sortBy === 'price') {
      if (direction === 'acs') {
        let newplots = plots
          .sort((a, b) => {
            return (
              parseFloat(a.price ? a.price.amount : '0') -
              parseFloat(b.price ? b.price.amount : '0')
            );
          })
          .map((item) => {
            item.key = (Math.random() + 1).toString(36).substring(7);
            return item;
          });
        setSortedPlots(newplots);
        setSortBy({ sort: 'price', direction: 'asc' });
      }

      if (direction === 'desc') {
        let newplots = plots
          .sort((a, b) => {
            return (
              parseFloat(a.price ? a.price.amount : '0') -
              parseFloat(b.price ? b.price.amount : '0')
            );
          })
          .reverse()
          .map((item) => {
            item.key = (Math.random() + 1).toString(36).substring(7);
            return item;
          });
        setSortedPlots(newplots);
        setSortBy({ sort: 'price', direction: 'desc' });
      }
    }

    if (sortBy === 'status') {
      if (direction === 'acs') {
        let newplots = plots
          .sort((a, b) => {
            return makeStatusSortable(a.status) - makeStatusSortable(b.status);
          })
          .map((item) => {
            item.key = (Math.random() + 1).toString(36).substring(7);
            return item;
          });
        setSortedPlots(newplots);
        setSortBy({ sort: 'status', direction: 'asc' });
      }

      if (direction === 'desc') {
        let newplots = plots
          .sort((a, b) => {
            return makeStatusSortable(a.status) - makeStatusSortable(b.status);
          })
          .reverse()
          .map((item) => {
            item.key = (Math.random() + 1).toString(36).substring(7);
            return item;
          });
        setSortedPlots(newplots);
        setSortBy({ sort: 'status', direction: 'desc' });
      }
    }

    if (sortBy === 'number') {
      if (direction === 'acs') {
        let newplots = plots
          .sort((a, b) => {
            return parseInt(a.number) - parseInt(b.number);
          })
          .map((item) => {
            item.key = (Math.random() + 1).toString(36).substring(7);
            return item;
          });
        setSortedPlots(newplots);
        setSortBy({ sort: 'number', direction: 'asc' });
      }

      if (direction === 'desc') {
        let newplots = plots
          .sort((a, b) => {
            return parseInt(a.number) - parseInt(b.number);
          })
          .reverse()
          .map((item) => {
            item.key = (Math.random() + 1).toString(36).substring(7);
            return item;
          });
        setSortedPlots(newplots);
        setSortBy({ sort: 'number', direction: 'desc' });
      }
    }
  };

  const toggleSort = (sort) => {
    let currentSort = sortBy;

    currentSort.sort = sort;
    currentSort.direction = currentSort.direction === 'asc' ? 'desc' : 'acs';
    setSortBy(currentSort);
    makeSort(currentSort.sort, currentSort.direction);
  };

  return (
    <div className='plots-overview page-padding'>
      {deleteModal && deleteModal.hasOwnProperty('id') && (
        <DeleteModal
          type={'plots'}
          item={deleteModal}
          closeModal={(e) => setDeleteModal()}
          options={deleteModal}
        />
      )}

      <div className='heading-with-button'>
        <Heading label={`${currentDevelopment.name} Plots`} />
        <Link to={`/developments/${developmentId}/plots/create`}>
          <Button label={'create plot'} />
        </Link>
      </div>

      <table cellSpacing={0}>
        <thead>
          <tr>
            <th width='150' onClick={(e) => toggleSort('number')}>
              <div className='table-sort-container'>
                <p>Plot Number</p>
                <span
                  className={sortBy && sortBy.sort === 'number' ? 'active' : ''}
                >
                  <p
                    className={
                      sortBy && sortBy.direction === 'asc' ? 'active' : ''
                    }
                  >
                    <svg viewBox='0 0 24 24' width='48' height='48'>
                      <path d='m1 4 11 18.3L23 4H1z' />
                    </svg>
                  </p>
                  <p
                    className={
                      sortBy && sortBy.direction === 'desc' ? 'active' : ''
                    }
                  >
                    <svg viewBox='0 0 24 24' width='48' height='48'>
                      <path d='m1 4 11 18.3L23 4H1z' />
                    </svg>
                  </p>
                </span>
              </div>
            </th>
            <th>House Type</th>
            <th onClick={(e) => toggleSort('status')}>
              <div className='table-sort-container'>
                <p>Status</p>
                <span
                  className={sortBy && sortBy.sort === 'status' ? 'active' : ''}
                >
                  <p
                    className={
                      sortBy && sortBy.direction === 'asc' ? 'active' : ''
                    }
                  >
                    <svg viewBox='0 0 24 24' width='48' height='48'>
                      <path d='m1 4 11 18.3L23 4H1z' />
                    </svg>
                  </p>
                  <p
                    className={
                      sortBy && sortBy.direction === 'desc' ? 'active' : ''
                    }
                  >
                    <svg viewBox='0 0 24 24' width='48' height='48'>
                      <path d='m1 4 11 18.3L23 4H1z' />
                    </svg>
                  </p>
                </span>
              </div>
            </th>
            <th onClick={(e) => toggleSort('price')}>
              <div className='table-sort-container'>
                <p>Price</p>
                <span
                  className={sortBy && sortBy.sort === 'price' ? 'active' : ''}
                >
                  <p
                    className={
                      sortBy && sortBy.direction === 'asc' ? 'active' : ''
                    }
                  >
                    <svg viewBox='0 0 24 24' width='48' height='48'>
                      <path d='m1 4 11 18.3L23 4H1z' />
                    </svg>
                  </p>
                  <p
                    className={
                      sortBy && sortBy.direction === 'desc' ? 'active' : ''
                    }
                  >
                    <svg viewBox='0 0 24 24' width='48' height='48'>
                      <path d='m1 4 11 18.3L23 4H1z' />
                    </svg>
                  </p>
                </span>
              </div>
            </th>
            <th>Bedrooms</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {sortedPlots.map((plot) => {
            return (
              <tr key={Math.random().toString(16)}>
                <td>Plot {plot.number}</td>
                <td>{makeIdHouseName(plot.houseType)}</td>
                <td>
                  <span
                    style={{
                      background: statusTypesAsOptions[plot.status]?.color,
                      opacity: 0.7,
                      color: shouldTextBeBlack(statusTypesAsOptions[plot.status]?.color) ? 'black' : 'white',
                    }}
                  >
                    {capitalize(statusTypesAsOptions[plot.status]?.label)}
                  </span>
                </td>
                <td>{formatCurrency(plot.price)}</td>
                <td>{calcPlotBedrooms(plot.houseType)} Bedrooms</td>
                <td className='right'>
                  <div className='table-buttons'>
                    <Link
                      to={`/developments/${currentDevelopment.id}/plots/${plot.id}/edit`}
                    >
                      <div className='table-button'>
                        <EditIcon />
                      </div>
                    </Link>
                    <div
                      className='table-button'
                      onClick={() =>
                        setDeleteModal({ id: plot.id, number: plot.number })
                      }
                    >
                      <DeleteIcon className='delete' />
                    </div>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const makeStatusSortable = (status) => {
  switch (status) {
    case 'available':
      return 0;
    case 'reserved':
      return 1;
    case 'sold':
      return 2;
    case 'future':
      return 3;
    default:
      return 4;
  }
};

export default PlotsTable;
