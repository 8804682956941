import { ApiError, beablooApi } from '../../utils';
import { setHouseViewMenu } from './optionalMenuItems';

const setHouseView = () => {
  return async (dispatch, getState) => {
    const { general } = getState();

    const response = await beablooApi({
      method: 'GET',
      route: `/developments/${general.currentDevelopment.id}/houseView`,
    })
      .then((r) => {
        if (r.success) {
          return r.data;
        } else {
          dispatch(setHouseViewMenu(false));
        }
      });
    if (response) {
      dispatch({
        type: 'SET_HOUSE_VIEW',
        data: response,
      });
    } 
  };
};

const addHouseView = (data) => {
    return {
      type: 'ADD_HOUSE_VIEW',
      data: data,
    };
  };
  
const updateHouseView = (data) => {
return {
    type: 'UPDATE_HOUSE_VIEW',
    data: data,
};
};

const deleteHouseView = (data) => {
return {
    type: 'DELETE_HOUSE_VIEW',
    data: data,
};
};

export { 
    setHouseView,
    addHouseView,
    updateHouseView,
    deleteHouseView
 };
