import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, SignTouchPreview } from '../../components';
import { PreviewHome } from '../../components/SignTouchPreview/pages';
import './styles.scss';
import { FormColorProvider } from '../../contexts/FormProvider';
import { beablooApi } from '../../utils';
import { AnimatePresence } from 'framer-motion';
import { useParams } from 'react-router-dom';
import { addNotification } from '../../utils';
import { useDispatch } from 'react-redux';
import { setProperties } from '../../store/actions/properties';
import { addError } from '../../utils';
import addPending from '../../utils/addPending';
import removePending from '../../utils/removePending';

const ParentProperties = () => {
  const { developmentId } = useParams();
  let dispatch = useDispatch();

  const properties = useSelector((state) => state.properties);
  const welcome = useSelector((state) => state.welcome);

  // pull out properties from the state
  const { name, branding } = properties;
  const [errors, setErrors] = useState([]);
  const [saving, setSaving] = useState(false);

  const [currentScreen, setCurrentScreen] = useState('home');

  // just testing some stuff this doesn't do anything important
  // use this as an example of how to overwrite the preview properties
  const [colors, setColors] = useState({
    primary: branding.primary,
    secondary: branding.secondary,
    tertiary: branding.tertiary,
    line: branding.line,
    section: branding.section,
  });

  const [previewName, setPreviewName] = useState({
    name: name,
  });

  const handleMainColors = (data) => {
    if (data.hasOwnProperty('name')) {
      setPreviewName({ name: data.name });
    }

    const payload = {
      primary: data.primary ? data.primary : branding.primary,
      secondary: data.secondary ? data.secondary : branding.secondary,
      tertiary: data.tertiary ? data.tertiary : branding.tertiary,
      startButton: data.startButton ? data.startButton : branding.startButton,
      startButtonText: data.startButtonText
        ? data.startButtonText
        : branding.startButtonText,
      line: data.line ? data.line : branding.line,
      card: data.card ? data.card : branding.card,
      section: data.section ? data.section : branding.section,
      logo: data.logo ? data.logo : branding.logo,
      map: {
        status1label: data.status1label ?? branding.map.status1label,
        status2label: data.status2label ?? branding.map.status2label,
        status3label: data.status3label ?? branding.map.status3label,
        status4label: data.status4label ?? branding.map.status4label,
        status5label: data.status5label ?? branding.map.status5label,
        status6label: data.status6label ?? branding.map.status6label,
        status7label: data.status7label ?? branding.map.status7label,
        status1: data.status1 ?? branding.map.status1,
        status2: data.status2 ?? branding.map.status2,
        status3: data.status3 ??  branding.map.status3,
        status4: data.status4 ?? branding.map.status4,
        status5: data.status5 ?? branding.map.status5,
        status6: data.status6 ?? branding.map.status6, 
        status7: data.status7 ?? branding.map.status7,

      },
      priceToggle: data.priceSwitch ?? branding.priceToggle
    };
    const color = addDefaultColor(payload.map);
    setColors({ ...payload, map: color });
  };

  const handleSubmit = async (data) => {
    setSaving(true);
    const pendingId = addPending('Saving Properties');

    beablooApi({
      method: 'PUT',
      route: `/developments/${developmentId}/properties/update`,
      payload: { branding: colors },
    }).then((result) => {
      setSaving(false);
      removePending(pendingId);

      if (!result.success) {
        addError('There was an error updating the properties.');
        setErrors(result.errors);
        return;
      }

      dispatch(setProperties({ properties, ...colors }));

      addNotification('Successfully updated properties.');
    });
  };

  const addDefaultColor = (colorMap) => {
    const labels = Object.keys(colorMap).filter((key) => key.includes('label'));
    if (labels.length > 0) {
      labels.forEach((label) => {
        const color = label.replace('label', '');
        if (!colorMap[color] && colorMap[`${color}label`] && colorMap[`${color}label`] !== '') {
          colorMap[color] = '#000000';
        } else if (!colorMap[`${color}label`] && colorMap[color]) {
          colorMap[color] = undefined;
        }
        if (colorMap[`${color}label`] === '') {
          colorMap[`${color}label`] = undefined;
        }
      });
    }
    return colorMap;
  }

  return (
    <>
      <div className='page-padding parent-properties'>
        <div className='parent-properties-forms'>
          <FormColorProvider>
            <Form
              errors={errors}
              inputs={[
                // { name: 'sepTitle', title: 'General Settings', type: 'split'},
                // { name: 'name', label: 'Company Name', type: 'text', initialValue: name },
                { name: 'sepMain', title: 'Main Colours', type: 'split' },
                {
                  name: 'primary',
                  label: 'Primary Colour',
                  type: 'colour',
                  initialValue: branding.primary,
                },
                {
                  name: 'secondary',
                  label: 'Secondary Colour',
                  type: 'colour',
                  initialValue: branding.secondary,
                },
                {
                  name: 'tertiary',
                  label: 'Tertiary Colour',
                  type: 'colour',
                  initialValue: branding.tertiary,
                },
                { name: 'sepLogo', title: 'logos', type: 'split' },
                {
                  name: 'logo',
                  label: 'logo',
                  type: 'single-image',
                  initialValue: branding.logo.url,
                },
                { name: 'sepUi', title: 'UI Colours', type: 'split' },
                {
                  name: 'startButton',
                  label: 'Start Button Colour',
                  type: 'colour',
                  initialValue: branding.startButton,
                },
                {
                  name: 'startButtonText',
                  label: 'Start Button Text Colour',
                  type: 'colour',
                  initialValue: branding.startButtonText,
                },
                {
                  name: 'line',
                  label: 'Line Break',
                  type: 'colour',
                  initialValue: branding.line,
                },
                { name: 'sepBack', title: 'Backgrounds', type: 'split' },
                {
                  name: 'card',
                  label: 'Card Background',
                  type: 'colourandgradient',
                  initialValue: branding.card,
                },
                {
                  name: 'section',
                  label: 'Section Background',
                  type: 'colourandgradient',
                  initialValue: branding.section,
                },
                { name: 'sepMap', title: 'Functions', type: 'split' },
                {
                  name:"priceSwitch",
                  label:"Hide Combined From Price",
                  type:"switch",
                  initialValue: branding.priceToggle ,
                },
                { name: 'sepMap', title: 'Map Colours', type: 'split' },
                {
                  name: 'status1',
                  label: 'status',
                  type: 'colour-with-text',
                  initialValue: branding.map.status1 ?? '#000000',
                  initialLabelValue: branding.map.status1label,
                },
                {
                  name: 'status2',
                  label: 'status',
                  type: 'colour-with-text',
                  initialValue: branding.map.status2 ?? '#000000',
                  initialLabelValue: branding.map.status2label,
                },
                {
                  name: 'status3',
                  label: 'status',
                  type: 'colour-with-text',
                  initialValue: branding.map.status3 ?? '#000000',
                  initialLabelValue: branding.map.status3label,
                },
                {
                  name: 'status4',
                  label: 'status',
                  type: 'colour-with-text',
                  initialValue: branding.map.status4 ?? '#000000',
                  initialLabelValue: branding.map.status4label,
                },
                {
                  name: 'status5',
                  label: 'status',
                  type: 'colour-with-text',
                  initialValue: branding.map.status5 ?? '#000000',
                  initialLabelValue: branding.map.status5label,
                },
                {
                  name: 'status6',
                  label: 'status',
                  type: 'colour-with-text',
                  initialValue: branding.map.status6 ?? '#000000',
                  initialLabelValue: branding.map.status6label,
                }
                /*
                {
                  name: 'future4',
                  label: 'Future Release',
                  type: 'colour-with-text',
                  initialValue: branding.map.future4 ?? '#000000',
                  initialLabelValue: branding.map.future4label,
                },
                {
                  name: 'future5',
                  label: 'Future Release',
                  type: 'colour-with-text',
                  initialValue: branding.map.future5 ?? '#000000',
                  initialLabelValue: branding.map.future5label,
                },
                {
                  name: 'future6',
                  label: 'Future Release',
                  type: 'colour-with-text',
                  initialValue: branding.map.future6 ?? '#000000',
                  initialLabelValue: branding.map.future6label,
                },
                {
                  name: 'future7',
                  label: 'Future Release',
                  type: 'colour-with-text',
                  initialValue: branding.map.future7 ?? '#000000',
                  initialLabelValue: branding.map.future7label,
                }
                */
              ]}
              onSubmit={handleSubmit}
              onChange={handleMainColors}
              canSubmit={!saving}
            />
          </FormColorProvider>
        </div>
        <div style={{ position: 'fixed', right: '10vw' }}>
          <AnimatePresence>
            <SignTouchPreview
              properties={{
                branding: {
                  ...colors,
                },
                name: previewName.name,
              }}
            >
              <PreviewHome />
            </SignTouchPreview>
          </AnimatePresence>
          {/* <div className='page-select'>
		      <button className='button button-table' onClick={e => setCurrentScreen('home')} >Home</button>
		      <button className='button button-table' onClick={e => setCurrentScreen('welcome')} >Welcome</button>
		      <button className='button button-table' onClick={e => setCurrentScreen('design-advice')} >Design Advice</button>
	      </div> */}
        </div>
      </div>
    </>
  );
};

export default ParentProperties;
