import React, { Suspense, useEffect, useState } from 'react'
import { Button, SidebarSeperator } from '../../components'
import { mapHouseViewAndHouseType } from '../../utils/combineHouseTypeAndHouseView';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteIcon } from '../../components/Icons';
import { Box, Grid, Switch } from '@mui/material';
import PanoramaViewer from '../../components/PanoramaViewer';
import { Canvas } from '@react-three/fiber';
import './styles.scss';
import { useMediaQuery } from 'react-responsive';
import { blobServices, houseViewService } from '../../lib';
import { useLocation, useParams } from 'react-router-dom';
import { addError } from '../../utils';
import { Html } from '@react-three/drei';
import { updateEnvironment } from '../../store/actions/streetView';
import addPending from '../../utils/addPending';
import removePending from '../../utils/removePending';
import { v4 as uuid } from 'uuid';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import { GpsNotFixed, Visibility } from '@mui/icons-material';
import { updateHouseView } from '../../store/actions/houseView';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const StreetViewMap = () => {
  const { pinId } = useParams();
  const query = useQuery();
  const houseViewId = query.get('houseViewId');
  const houseView = useSelector((state) => state.houseView);
  const houseTypes = useSelector((state) => state.houseTypes);
  const [rerender, setRerender] = useState(false);
  const { environmentConfig } = useSelector((state) => state.environmentConfig);
  const { branding } = useSelector((state) => state.properties);
  const [loading, setLoading] = useState(true);
  const [combinedData, setCombinedData] = useState([]);
  const [inOpreation, setInOperation] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [follow, setFollow] = useState({x: 0, y: 0});
  const [clickedData, setClickedData] = useState(null);
  const [selectedFloor, setSelectedFloor] = useState(null);
  const [beingViewed, setBeingViewed] = useState(null);
  const [panoramas, setPanoramas] = useState(null);
  const [mode, setMode] = useState('default');
  const [editor, setEditor] = useState(false);
  const isSmallScreen = useMediaQuery({ query: '(max-width: 1200px)' });
  const menuWidth = isSmallScreen ? 0 :(document.getElementsByClassName('side-bar')[0]?.offsetWidth ?? 57);
  const topBarHeight = document.getElementsByClassName('development-banner')[0]?.offsetHeight ?? 262;
  const dispatch = useDispatch();

  const savePin = async (data) => {
    const pending = addPending('Saving...');
    setInOperation(true);
    setEditor(false);
    if (mode === 'default') {
      if (!selectedOption) {
        addError('Please select a house type');
        return
      };
      const fileName = 'EnviornmentConfigrations.json';
      const payload = {
        id: uuid(),
        position: data.point,
        house_type_id: selectedOption.houseTypeId,
        house_view_id: selectedOption.houseViewId,
      }
      let response = environmentConfig;
      if (response[`house_entry_links_${pinId}`]) {
        response[`house_entry_links_${pinId}`] = 
        [...response[`house_entry_links_${pinId}`], payload];
      } else {
        response = {
          ...response,
          [`house_entry_links_${pinId}`]: [payload]
        };
      }
      await blobServices.uploadSingle(fileName, response).then(() => {
        dispatch(updateEnvironment(response));
        removePending(pending);
        setInOperation(false);
      }).catch((error) => {
        addError('Error saving pin');
        removePending(pending);
        setInOperation(false);
      });
    } else if (mode === 'house-view') {
      if (!clickedData) {
        addError('no option selected');
        return
      };
      const payload = {
        id: uuid(),
        position: data.point,
        house_type_id: clickedData?.houseType?.id,
        house_view_id: clickedData?.houseView?.id ,
        optionId: beingViewed?.id,
        pinOptionId: selectedOption?.id
      }
      const [selectHouseView] = houseView.filter(view => view.id === clickedData?.houseView?.id);
      const filterOptions = selectHouseView.options.filter(option => option.id !== beingViewed?.id);
      
      const mergePayload = {
        options: [
          ...filterOptions,
          {
            ...beingViewed,
            azimuthalAngle: data.azimuthalAngle,
          }
        ],
        housePaths: [
          ...selectHouseView.housePaths,
          payload
        ]
      };
      await houseViewService.updateHouseView(mergePayload, clickedData?.houseView?.id)
      .then((res) => {
        dispatch(updateHouseView(res));
        removePending(pending);
        setRerender(!rerender);
        setInOperation(false);
      })
      .catch((error) => {
        addError('Error saving pin');
        removePending(pending);
        setInOperation(false);
      });
    };
  }

  const deletePin = async (data) => {
    const pending = addPending('Deleting...');
    setInOperation(true);
    if (!beingViewed && mode === 'delete') {
      let response = environmentConfig;
      if (response[`house_entry_links_${pinId}`]) {
        response[`house_entry_links_${pinId}`] = response[`house_entry_links_${pinId}`].filter(pin => pin.id !== data?.data?.id);
      }
      await blobServices.uploadSingle('EnviornmentConfigrations.json', response).then(() => {
        dispatch(updateEnvironment(response));
        removePending(pending);
      }).catch((error) => {
        addError('Error deleting pin');
        removePending(pending);
      }).finally(()=> {
        setInOperation(false);
      });
    } else if (beingViewed && mode === 'delete') {
      const pathToDelete = data?.data?.id;
      const filterHousePaths = data?.houseView.housePaths.filter(path => path.id !== pathToDelete);

      const payload = {
        housePaths: filterHousePaths
      }
      await houseViewService.updateHouseView(payload, data.houseView.id).then((res) => {
        dispatch(updateHouseView(res));
        removePending(pending);
        setRerender(!rerender);
        setInOperation(false);
      }).catch((error) => {
        addError('Error deleting pin');
        removePending(pending);
        setInOperation(false);
      });

    }
  }

  const saveAngleForPin = async (data) => {
    const pending = addPending('Saving Defalult Angle');
    setInOperation(true);
    if (!beingViewed) {
      const fileName = 'EnviornmentConfigrations.json';
      let response = environmentConfig;
      response = {
        ...response,
        [`pin_${pinId}_settings`]: { azimuthalAngle: data.azimuthalAngle }
      };
      await blobServices.uploadSingle(fileName, response).then(() => {
        dispatch(updateEnvironment(response));
        removePending(pending);
        setInOperation(false);
      }).catch((error) => {
        addError('Error saving pin');
        removePending(pending);
        setInOperation(false);
      });
    } else {
      if (!clickedData) {
        addError('no option selected');
        return
      };
      const [selectHouseView] = houseView.filter(view => view.id === clickedData?.houseView?.id);
      const filterOptions = selectHouseView.options.filter(option => option.id !== beingViewed?.id);
      const mergePayload = {
        options: [
          ...filterOptions,
          {
            ...beingViewed,
            azimuthalAngle: data.azimuthalAngle,
          }
        ]
      };
      await houseViewService.updateHouseView(mergePayload, clickedData?.houseView?.id)
      .then((res) => {
        dispatch(updateHouseView(res));
        removePending(pending);
        setRerender(!rerender);
        setInOperation(false);
      })
      .catch((error) => {
        addError('Error saving pin');
        removePending(pending);
        setInOperation(false);
      });
    };
  };
  // for combining house view and house type

  useEffect(() => {
    mapHouseViewAndHouseType(houseView, houseTypes).then((combined) => {
      setCombinedData(combined);
      setLoading(false);
    });
  }, [houseTypes, houseView]);
  // for follow div to follow mouse pointer
  useEffect(() => {
    const handleMouseMove = (e) => {
      setFollow({x: (e.clientX - 6) - menuWidth , y: (e.clientY - 6) - topBarHeight});
    }
    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    }
  }, [isSmallScreen, menuWidth, topBarHeight]);

  useEffect(() => {
    if (houseViewId) {
      const [houseViewData] = houseView.filter(view => view.id === parseInt(houseViewId));
      const [houseTypeData] = houseTypes.filter(type => type.id === houseViewData.houseTypeId);
      setClickedData({ houseType: houseTypeData, houseView: houseViewData });
      setBeingViewed(houseViewData.options[0]);
      setMode('house-view');
    }
  }, [mode]);

  return (
    <Grid container spacing={2}>
      <Grid item sm={9}>
        <Box className={'position-relative'}>
          <Canvas className="main-content-sizing">
            <Suspense fallback={
              <Html fullscreen>
                <div style={{ display:'flex', justifyContent:'center', alignItems: 'center', width: '100%', height:'100vh' }}>
                  loading...
                </div>
              </Html>
              }> 
                <PanoramaViewer parentPanoramas={panoramas} setParentPanoramas={setPanoramas} mode={mode} setMode={setMode} inOpreation={inOpreation}
                  savePin={savePin} saveAngle={saveAngleForPin} deletePin={deletePin} setClickedData={setClickedData} editor={editor} setEditor={setEditor}
                  beingViewed={beingViewed} setBeingViewed={setBeingViewed} rerender={rerender} houseViewId={houseViewId}
                />
            </Suspense>
          </Canvas>
          {editor && 
            <div className={'cancel-action'} style={{ backgroundColor: branding?.primary}}>
              <div>
                <p>Placing {selectedOption?.name}</p>
                <Button label={'Canel'} onClick={()=> setEditor(false)}/>
              </div>
            </div>
          }
        </Box>
        {editor && <div className='follow-div-street' style={{ left: `${follow.x}px`, top: `${follow.y}px` }}/>}
      </Grid>
      <Grid item sm={3} className='action-pane'>
        <div style={{ display: 'flex', justifyContent:'space-between', alignItems: 'center' }}>
          <h1 style={{ marginBottom: '5px' }}>
            {beingViewed ? clickedData.houseType?.name : `Pin ${pinId}`}
          </h1>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>delete mode
            <Switch color='error' onChange={(e)=> setMode(e.target.checked ? 'delete' : beingViewed ? 'house-view' : 'default')}/>
          </div>
        </div>
        <h4 style={{ marginTop: 0 }}>
          {beingViewed 
          ? 'Select Room / Area to place on floor' 
          : 'Select house type to place on street view'}
        </h4>
        <SidebarSeperator styles={{ width: '100%' }} />
        <div className='overflow-content-map'>
          {(combinedData && mode === 'default' || !beingViewed) && combinedData.map((option, index) => (
            <React.Fragment key={index}>
              {option.floors.length > 0 && 
              <>
              <div className={ 'expandable-content show'  }>
                <div
                  className={`floor ${selectedOption && selectedOption.houseTypeId === option.houseTypeId ? 'selected' : ''}`}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (mode === 'delete') {
                      addError('Please exit delete mode to select');
                      return;
                    }
                    setEditor(true);
                    setSelectedOption(option);
                  }}>
                    <p>{option.name ? option.name : 'Error House Type'}</p>
                </div>
              </div>
              <SidebarSeperator styles={{ width: '100%' }} />
              </>
              }
            </React.Fragment>
          ))}
          {(mode === 'house-view' && clickedData || beingViewed) &&
            clickedData?.houseView?.floors.map((option, index) => (
              <React.Fragment key={index}>
                <div className={ `expandable-content ${selectedFloor?.id === option.id ? 'selected' : ''}` }>
                  <div
                    className={`floor}`}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      if (mode === 'delete') {
                        addError('Please exit delete mode to select');
                        return;
                      }
                      if (selectedFloor?.id === option.id) {
                        setSelectedFloor(null);
                        return;
                      }
                      setSelectedFloor(option);
                    }}>
                      <p style={{ display: 'flex', justifyContent:'space-between' }}>
                        <div>
                          {option.name}
                        </div>
                        {selectedFloor?.id === option.id 
                        ? <ChevronUpIcon className='expandable-icon' />
                        : <ChevronDownIcon className='expandable-icon' /> }
                      </p>
                  </div>
                  <div style={{ display: selectedFloor?.id === option.id ?  'block' : 'none', marginLeft: '1rem', fontWeight: 'normal' }}>
                    {clickedData.houseView.options && 
                      clickedData.houseView.options.map((option, index) => (
                        <React.Fragment key={index}>
                          {selectedFloor?.id === option.floorId &&
                            <div key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                              <p>{option.name}</p>
                              <div>
                                {beingViewed?.id !== option.id && 
                                  <Visibility style={{ fontSize: '1rem' ,marginRight: '10px', cursor: 'pointer'}}
                                    onClick={() => {
                                      if (mode === 'delete') {
                                        addError('Please exit delete mode to select');
                                        return;
                                      }
                                      if (option.panoramas.length !== 6) {
                                        addError('Panorama files are missing');
                                        return;
                                      }
                                      setPanoramas(option);
                                      setBeingViewed(option);
                                    }}
                                  />
                                }
                                <GpsNotFixed style={{ fontSize: '1rem', cursor: 'pointer'}}
                                  onClick={() => {
                                    if (mode === 'delete') {
                                      addError('Please exit delete mode to select');
                                      return;
                                    }
                                    setEditor(true);
                                    setSelectedOption(option);
                                  }}
                                />
                              </div>
                            </div>}
                        </React.Fragment>)
                      )
                    }
                  </div>
                </div>
                <SidebarSeperator styles={{ width: '100%' }} />
              </React.Fragment>))
          }
        </div>
      </Grid>
    </Grid>
  )
}

export default StreetViewMap